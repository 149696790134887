import React from "react";
import { Link } from "react-router-dom";
import '../../css/footer.css';
import logo from "../../images/icons/crawebsitelogo82x82.svg";
import facebook from "../../images/icons/facebook.svg";
import linkedin from "../../images/icons/linkedin.svg";
import instagram from "../../images/icons/instagram.svg";
import hippa from "../../images/icons/hippa.svg";
import BBB from '../../images/icons/BBBLogo.png';


function Footer() {
    const currDate = new Date().getFullYear();
    return (
        <div className="footer">
            <div className="box first">
                <img className="logo-footer" src={logo} alt="Logo" />
                <div className="navlist">
                    <div className="inner-nav">
                        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
                        <Link className="below-home" id='pricing' to="/pricing" style={{ textDecoration: 'none', color: 'white' }}>Pricing</Link>
                        <a className="below-home" target="_blank" href="https://mymeddiary.app/onboarding" style={{ textDecoration: 'none', color: 'white' }} >myMedDiary&reg;</a>
                    </div>
                    <div className="inner-nav inner-two">
                        <Link className="below-home" to="/blog" style={{ textDecoration: 'none', color: 'white' }}>Blog</Link>
                        {/*<Link className="below-home" to="/pressrelease" style={{ textDecoration: 'none', color: 'white' }}>Press Release</Link>*/}
                        {/*<Link className="below-home" to="/team" style={{ textDecoration: 'none', color: 'white' }}>Team</Link>*/}
                        <a className="below-home" target="_blank" href="https://forms.gle/PmSLiSF22oPcu8Eq5" style={{ textDecoration: 'none', color: 'white' }} >Report Bug</a>
                    </div>
                </div>
            </div>
            <div className="second">
                <div className="boxsecond-socials">
                    {/* facebook link */}
                    <a target="_blank" href="https://www.facebook.com/CompReApplied">
                        <img className="social-icon" src={facebook} alt="facebook icon" />
                    </a>
                    {/* linkedin link */}
                    <a target="_blank" href="https://www.linkedin.com/company/computing-reapplied/">
                        <img className="social-icon" src={linkedin} alt="linkedin icon" />
                    </a>
                    {/* instagram link */}
                    <a target="_blank" href="https://www.instagram.com/computingreapplied/?hl=en">
                        <img className="social-icon" src={instagram} alt="instagram icon" />
                    </a>
                </div>
                <div className="inline">
                    <img className="hippa-icon" src={hippa} alt="Hipaa compliant certification" />
                    <img className="BBB-icon" src={BBB} alt="BBB logo" />
                </div>
            </div>
            <div className="box third">
                <div className="address">
                    <h4>© {currDate} Computing ReApplied&trade;</h4>
                    <p>16509 NE 50th Way Redmond, WA 98052</p>
                </div>
                <div className="contact">
                    <h4>Support Contact</h4>
                    <p><a href="mailto:contact-us@computingreapplied.com">contact-us@computingreapplied.com</a></p>
                    <p>(971) 319-3535</p>
                </div>
            </div>
        </div >
    );
}

export default Footer;
