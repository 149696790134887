import React from "react";
import { Link } from 'react-router-dom';

function PressReleaseArticle(props: any) {
    let blogpost = props.articleData;
    let redirectTo = '/pressreleasearticle/' + blogpost.id;
    console.log('here is the article post image: ' + blogpost.image);
    return (
        <div className="blogpost-container">
            <Link to={redirectTo} style={{ textDecoration: 'none', color: '#002145' }}>
                <div className="blogimage-container">
                    <img className="blogpost-image" src={blogpost.image} alt="Blog post cover art" />
                </div>
                <h3 className="blogpost-name" >{blogpost.name}</h3>
                <div className="authordate-container">
                    <h4 className="blogpost-author">{blogpost.author}</h4>
                    <h4 className="blogpost-date" >{blogpost.date}</h4>
                </div>
                <div className="blogpost-description">
                    <p>{blogpost.description}</p>
                </div>
            </Link>
        </div>
    );
}

export default PressReleaseArticle;