import React from "react";
// Images
import blogBulletPoint from '../../images/blog/blog-bulletpoint.svg';
//Components
import PressReleaseList from './components/PressReleaseList';
// CSS
import '../../css/blog.css';
import '../../css/general.css';
import '../../css/blog-responsive.css';

function PressRelease() {
    return (
        <div className="blog-container">
            <div className="blog-header">
                <img src={blogBulletPoint} alt="bullet point" />
                <h1 style={{fontFamily: "var(--lora)", fontWeight: 400, fontSize: "2.4em", marginRight: "0.35em"}}>View the Latest News on </h1>
                <h1>C-RA</h1>
                <h2>&trade;</h2>
            </div>
            <PressReleaseList />
        </div>
    );
}
export default PressRelease;
