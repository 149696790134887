import React from "react";

function TeamMember(props: any) {
    let teamMember = props.member;
    return (
        <div className="teammember-container">
            <div className="teammemberimage-container">
                <a target="_blank" href={teamMember.linkedin}>
                    <img className="teammember-image" src={teamMember.image} alt="team member image" />
                    </a>
            </div>
            <h3 className="teammember-name" >{teamMember.name}</h3>
            <h4 className="teammember-position">{teamMember.position}</h4>
            {/* <div className="teammember-description">
                <p>{teamMember.description}</p>
            </div> */}
        </div>
    );
}

export default TeamMember;