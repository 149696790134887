
import React, { useEffect, useState, useRef } from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../../assets/Color/Color';

interface CustomProps {
  isVisible: boolean;
  delay: string;
}

const Container = styled(Paper)(({ theme }) => ({
  backgroundColor: colors.blueButton,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
  padding: '20px 0 100px 0',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: 'Sansation',
  color: colors.whitefont,
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  marginTop: '10px',
}));

const SubHeader = styled(Typography)({
  fontFamily: 'Avenir',
  color: colors.whitefont,
  textAlign: 'center',
  marginBottom: '110px',
});

const ContentContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '400px',
});

const PhoneImage = styled('img')({
  width: '100%',
  height: 'auto',
  maxWidth: '750px',
});

const StyledLabel = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<CustomProps>(({ isVisible, delay }) => ({
  position: 'absolute',
  left: '-150px',
  top: '6%',
  transform: 'translateY(-50%)',
  width: '300px',
  opacity: isVisible ? 1 : 0,
  transition: `opacity 0.5s ease ${delay}`,
}));

const StyledLabel2 = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<CustomProps>(({ isVisible, delay }) => ({
  position: 'absolute',
  left: '590px',
  top: '22%',
  transform: 'translateY(-50%)',
  width: '300px',
  opacity: isVisible ? 1 : 0,
  transition: `opacity 0.5s ease ${delay}`,
}));

const StyledLabel3 = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<CustomProps>(({ isVisible, delay }) => ({
  position: 'absolute',
  left: '-90px',
  top: '65%',
  transform: 'translateY(-50%)',
  width: '300px',
  opacity: isVisible ? 1 : 0,
  transition: `opacity 0.5s ease ${delay}`,
}));

const StyledLabel4 = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<CustomProps>(({ isVisible, delay }) => ({
  position: 'absolute',
  left: '550px',
  top: '88%',
  transform: 'translateY(-50%)',
  width: '300px',
  opacity: isVisible ? 1 : 0,
  transition: `opacity 0.5s ease ${delay}`,
}));

const Desktop5: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            setIsVisible(true);
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <Container elevation={3} ref={containerRef}>
      <Header variant="h4">
        Case Study:
        <br /> Seattle Children's Hospital
      </Header>
      <SubHeader variant="subtitle1">
        Compared to 24-hour dietary recall methods, customized <br /> myMedDiary can record nutritional data with a higher precision
      </SubHeader>
      <ContentContainer>
        <StyledLabel src='../../img/newhome/30X.svg' alt="Metric Indicator" isVisible={isVisible} delay="0.5s" />
        <StyledLabel2 src='../../img/newhome/Custom.svg' alt="Metric Indicator" isVisible={isVisible} delay="1s" />
        <StyledLabel3 src='../../img/newhome/Track.svg' alt="Metric Indicator" isVisible={isVisible} delay="1.5s" />
        <StyledLabel4 src='../../img/newhome/48per.svg' alt="Metric Indicator" isVisible={isVisible} delay="2s" />
        <PhoneImage src="../../img/newhome/RecipePhoneScreenLarge.svg" alt="Mobile Diagram" />
      </ContentContainer>
    </Container>
  );
};

export default Desktop5;
