
import React, { useEffect, useState, useRef } from 'react';
import { Paper, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { colors } from '../../../assets/Color/Color';

const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },
};

const MobileContainer = styled(Paper)(({ theme }: { theme: Theme }) => ({
  backgroundColor: colors.blueButton,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: colors.whitefont,
  minHeight: '40vh',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const Header = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ isVisible, delay }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '27px',
  fontFamily: 'Sansation',
  marginTop: '50px',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
}));

const SubHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ isVisible, delay, theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontFamily: 'Avenir',
  fontSize: '18px',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
}));

const ContentContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '95%',
  margin: '67px auto',
});

const PhoneImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '70%',
  height: '300px',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: isVisible ? `fadeIn 1s forwards 1.5s` : 'none',
  ...fadeIn,
}));

const NodesImage = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '95%',
  height: 'auto',
  position: 'relative',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: isVisible ? `fadeIn 1s forwards 2s` : 'none',
  ...fadeIn,
}));

const PositionedImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay' && prop !== 'style',
})<{ isVisible: boolean; delay: string; style: React.CSSProperties }>(({ isVisible, delay, style }) => ({
  position: 'absolute',
  width: '120px',
  height: 'auto',
  opacity: 0,
  transform: 'translateY(20px)',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
  ...style,
}));

const Desktop6 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once it's visible
          }
        });
      },
      { threshold: 0 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <MobileContainer elevation={3} ref={containerRef}>
      <Header variant="h5" isVisible={isVisible} delay="0.5s">
        Case Study:<br /> Seattle Children’s Hospital
      </Header>
      <SubHeader variant="body1" isVisible={isVisible} delay="1s">
        Compared to traditional 24-hour dietary capture methods,<br />
        myMedDiary can record nutritional data with more precision.
      </SubHeader>
      <ContentContainer>
        <PhoneImage src="../../img/newhome/newrecipemockup.svg" alt="Phone" isVisible={isVisible} />
        <NodesImage isVisible={isVisible}>
          <img src="../../img/newhome/Nodes.png" alt="Nodes" style={{ width: '30%', height: 'auto' }} />
          <PositionedImage src="../../img/newhome/thirtyX.svg" style={{ top: '-35px', left: '35%' }} alt="Top Icon" isVisible={isVisible} delay="2.5s" />
          <PositionedImage src="../../img/newhome/Custom2.svg" style={{ top: '20%', left: '35%' }} alt="Right Icon" isVisible={isVisible} delay="3s" />
          <PositionedImage src="../../img/newhome/48per2.svg" style={{ top: '50%', left: '35%' }} alt="Bottom Icon" isVisible={isVisible} delay="3.5s" />
          <PositionedImage src="../../img/newhome/Track2.svg" style={{ top: '87%', left: '35%' }} alt="Left Icon" isVisible={isVisible} delay="4s" />
        </NodesImage>
      </ContentContainer>
    </MobileContainer>
  );
};

export default Desktop6;
