import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { colors } from '../../../assets/Color/Color';
import { keyframes } from '@emotion/react';

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

// Testimonials data
const testimonials = [
  {
    text: "myMedDiary streamlines dietary data capture and harnesses technology to collect more detailed data.",
    author: "Dr. Dale Lee, MD, MSCE",
    position: "Director of Clinical Nutrition & Director of Celiac Disease Program at Seattle Children's Hospital"
  },
  {
    text: "The food database and data collection workflow are two of myMedDiary's strongest points. Nearly all pre-packaged and raw foods are available, and the scanning feature for pre-packaged foods is as easy as making an electronic deposit with your phone. What makes this such a good option for research is the collected diet data can easily be analyzed using a spreadsheet-based application, or even with something like Python or R.",
    author: "Kimberly Braly, RD",
    position: "CEO of Kim Braly Nutrition LLC & IBD Nutrition Consultant"
  }
];

// Brand logos with specified dimensions and responsive dimensions
const brands = [
  { src: "../../img/newhome/RsLogo.png", alt: "Brand 1", width: '277px', height: '65px', mobileWidth: '127px', mobileHeight: '50px' },
  { src: "../../img/newhome/jumpstart.png", alt: "Brand 2", width: '280px', height: '48px', mobileWidth: '128px', mobileHeight: '22px' },
  { src: "../../img/newhome/seattlechildrenslogo.svg", alt: "Brand 3", width: '277px', height: '118px', mobileWidth: '104px', mobileHeight: '54px' },
  { src: "../../img/newhome/microsoftlogo.svg", alt: "Brand 4", width: '229px', height: '59px', mobileWidth: '105px', mobileHeight: '27px' },
  { src: "../../img/newhome/bbblogo.svg", alt: "Brand 1", width: '90px', height: '90px', mobileWidth: '60px', mobileHeight: '50px' },
  { src: "../../img/newhome/life.png", alt: "Brand 2", width: '250px', height: '80px', mobileWidth: '128px', mobileHeight: '40px' },
  { src: "../../img/newhome/safkan.png", alt: "Brand 3", width: '160px', height: '55px', mobileWidth: '80px', mobileHeight: '54px' },
  { src: "../../img/newhome/statlinklogo.svg", alt: "Brand 4", width: '120px', height: '90px', mobileWidth: '80px', mobileHeight: '50px' },
];

// Define keyframes for animations
const fadeOutLeft = keyframes`
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(100%); }
`;

const fadeInRight = keyframes`
  0% { opacity: 0; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(0); }
`;

const TestimonialAndBrandComponent = () => {
  const [currentLogos, setCurrentLogos] = useState(brands.slice(0, 4));
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationClass('fade-out-left');
      setTimeout(() => {
        setCurrentLogos(prevLogos => {
          const nextStartIndex = (brands.indexOf(prevLogos[0]) + 4) % brands.length;
          return brands.slice(nextStartIndex, nextStartIndex + 4);
        });
        setAnimationClass('fade-in-right');
      }, 500); // Duration of fade-out animation
    }, 4000); // Duration between transitions

    return () => clearInterval(interval);
  }, []);

  return (
    <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', overflowX: 'hidden', margin: 'auto', width: '95%', boxShadow: 'none', border: 'none' }}>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{
            width: { xs: '340px', md: '400px' }, // Smaller width on XS screens
            height: { xs: 'auto', md: 'auto' }, // Smaller height on XS screens
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '15px',
            justifyContent: 'center'
            , backgroundColor: colors.backgroundBlue,
            padding: { xs: '35px 45px 35px 65px', md: '50px 45px 50px 45px' },
            marginLeft: '15px',
            marginBottom: '25px'
          }}>
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <Box key={index} sx={{ textAlign: 'center', marginBottom: '30px' }}>
                  <Typography variant="h6" sx={{ fontStyle: 'italic', marginBottom: '27px', fontSize: { xs: '18px', md: '24px' }, fontWeight: 'bold', color: colors.blueButton, fontFamily: 'Sansation', }}>{testimonial.text}</Typography>
                  <Typography variant="subtitle1" sx={{ color: colors.blueButton }}>{testimonial.author}</Typography>
                  <Typography variant="caption" sx={{ marginBottom: '34px', color: colors.blueButton }}>{testimonial.position}</Typography>
                </Box>
              ))}
            </Slider>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', padding: 2, marginTop: { xs: '102px' } }}>
          {currentLogos.map((brand, index) => (
            <Grid item xs={6} key={index} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: { xs: '40px', md: '50px' },
              animation: animationClass === 'fade-out-left' ? `${fadeOutLeft} 0.5s forwards` : `${fadeInRight} 0.5s forwards`
            }}>
              <Box component="img" src={brand.src} alt={brand.alt} sx={{
                width: { xs: brand.mobileWidth, md: brand.width },
                height: { xs: brand.mobileHeight, md: brand.height }
              }} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TestimonialAndBrandComponent;
