import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Pricing, Blog, Team, Header, Footer } from "./pages/index";
import UserTermsAndAgreement from "./pages/Userterms/UserTermsAndAgreement";
import BlogArticlePage from "./pages/Blog/components/BlogArticlePage";
import PressReleaseArticlePage from "./pages/PressRelease/components/PressReleaseArticlePage";
import './css/App.css';
import PressRelease from "./pages/PressRelease/PressRelease";
//import Hero from "./pages/Hero/Hero";
import { Redirect } from 'react-router-dom';
import NewHome from "./pages/NewHome/NewHome";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home" component={NewHome} />
        <Route exact path="/moreInfo" component={Home} />
        {/*<Route path="/pricing" component={Pricing} />*/}
        <Route path="/blog" component={Blog} />
        <Route path="/article/:articleid" component={BlogArticlePage} />
        <Route path="/pressrelease" component={PressRelease} />
        <Route path="/pressreleasearticle/:articleid" component={PressReleaseArticlePage} />
        {/*<Route path="/team" component={Team} />*/}
        <Route path="/utcad" component={UserTermsAndAgreement} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
