import React from "react";
// Components
import TeamMember from './TeamMember';
//Data
import teamdata from './teamdata.json';


const data = teamdata.map(function (data, i) {
    return (
        <TeamMember member={data} key={i} />
    )
});

function TeamList() {
    return (
        <div className="teaminformation-container">
            <h2>Meet The Team</h2>
            <div className="teamlist-container">
                {data}
            </div>
        </div >
    );
}

export default TeamList;