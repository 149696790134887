import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#002B59",
      light:"#E5F8FD",
      dark:"#5FBDD6",
      contrastText: '#7292AB',
    },
    secondary:{
        main:"#FFFFFF",
        light:"#F7FDFF",
        dark:"#116E87",
        contrastText: 'rgba(255, 255, 255, 0.6)',
    },
    warning:{
        main:"#E49152",
        dark:"#E3AD84",
        
        
    },
  },
});

export default theme;
