

import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { colors } from '../../../assets/Color/Color';

const MainBox = styled(Box)({
  width: '100%',
  height: 'auto',
  backgroundColor: colors.backgroundBlue,
  marginBottom: '50px',
});

const StyledBox = styled(Box)({
  padding: '40px 32px', // Adjust padding for overall component
  maxWidth: '800px',
  margin: 'auto',
  textAlign: 'center', // Ensure text is centered
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '48px', // Default font size for larger screens
  color: colors.blueButton,
  fontFamily: 'Sansation',
  marginBottom: '100px',
  marginTop: '100px',
  opacity: 0, // Initial opacity for animation
  transform: 'translateY(-20px)', // Initial transform for animation
  transition: 'opacity 0.5s ease, transform 0.5s ease', // Transition for animation
  [theme.breakpoints.down('sm')]: {
    // Apply styles for screen sizes below 600px
    fontSize: '24px', // Smaller font size for small screens
    marginBottom: '55px',
    marginTop: '50px',
  },
}));

const FeatureBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center', // Center align items
  marginBottom: '100px', // Default space between feature boxes for larger screens
  justifyContent: 'start', // Align items to the start
  width: '100%', // Ensure box uses full width for alignment
  opacity: 0, // Initial opacity for animation
  transform: 'translateY(-20px)', // Initial transform for animation
  transition: 'opacity 0.5s ease, transform 0.5s ease', // Transition for animation
  [theme.breakpoints.down('sm')]: {
    // Apply styles for screen sizes below 600px
    marginBottom: '70px', // Smaller margin bottom for small screens
  },
}));

const IconWrapper = styled('img')(({ theme }) => ({
  marginRight: '40px', // Space between icon and text
  width: '65px', // Default icon size for larger screens
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    // Apply styles for screen sizes below 600px
    width: '43px', // Smaller icon size for small screens
    marginRight: '22px', // Reduced space between icon and text
  },
}));

const FeatureText = styled(Typography)(({ theme }) => ({
  color: colors.blueButton,
  fontWeight: '500',
  fontFamily: 'Avenir',
  fontSize: '32px', // Default font size for larger screens
  textAlign: 'left',
  flex: '1',
  [theme.breakpoints.down('sm')]: {
    // Apply styles for screen sizes below 600px
    fontSize: '14px', // Smaller font size for small screens
    display: 'none',
  },
}));

const FeatureText2 = styled(Typography)(({ theme }) => ({
  fontFamily: 'Avenir',
  color: colors.blueButton,
  fontSize: '16px', // Default font size for larger screens
  textAlign: 'left',
  flex: '1',
  [theme.breakpoints.up('sm')]: {
    // Apply styles for screen sizes below 600px
    display: 'none',
  },
}));

const MyMedDiaryFeatures: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            setIsVisible(true);
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <MainBox ref={containerRef}>
      <StyledBox>
        <Heading
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transitionDelay: isVisible ? '0s' : '0s',
          }}
        >
          Why myMedDiary<br /> For Data Collection?
        </Heading>
        <FeatureBox
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transitionDelay: isVisible ? '0.5s' : '0s',
          }}
        >
          <IconWrapper src="../../img/newhome/clock.svg" alt="Icon 1" />
          <FeatureText>
            Current paper based data collection methods can take weeks to obtain data from studies.
          </FeatureText>
          <FeatureText2>
            Current paper based data collection methods can take weeks to obtain data from studies.
          </FeatureText2>
        </FeatureBox>
        <FeatureBox
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transitionDelay: isVisible ? '1s' : '0s',
          }}
        >
          <IconWrapper src="../../img/newhome/dollar.svg" alt="Icon 2" />
          <FeatureText>
            myMedDiary solves this 100+ hours problem in seconds while reducing costs by over 60%.
          </FeatureText>
          <FeatureText2>
            myMedDiary solves this 100+ hours problem in seconds while reducing costs by over 60%.
          </FeatureText2>
        </FeatureBox>
        <FeatureBox
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transitionDelay: isVisible ? '1.5s' : '0s',
          }}
        >
          <IconWrapper src="../../img/newhome/slate.svg" alt="Icon 3" />
          <FeatureText>
            A new level of precise data that can lead to a 30% increase in revenue for institutions through internal quality improvements analysis.
          </FeatureText>
          <FeatureText2>
            A new level of precise data that can lead to a 30% increase in revenue for institutions through internal quality improvements analysis.
          </FeatureText2>
        </FeatureBox>
      </StyledBox>
    </MainBox>
  );
};

export default MyMedDiaryFeatures;
