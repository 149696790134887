import React from "react";
//Images
import vision from '../../../images/homepage/vision.jpg';
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ContactButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "20rem",
        height: "2.5rem",
        fontSize: "1em",
        backgroundColor: "#ffffff",
        color: "#002145",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#002145",
            color: "#ffffff",
        },
        '@media only screen and (max-width: 580px)': {
            border: "solid 2px #002145",
            fontWeight: '500',
            width: "95%",
            height: "3.2rem",
        }
    },
}))(Button);

function Vision() {
    return (
        <div className="vision-container">

            <div className="vision-contents">
                {/* vision image */}
                <div className="vision-image">
                    <img src={vision} alt="Medical researchers working" />
                </div>
                {/* vision text and button */}
                <div className="vision-text-container">
                    <div className="vision-title">
                        <h5>Vision</h5>
                    </div>
                    <h2>Data For a Cause</h2>
                    <p className="vision-p">What’s the next step in the optimization of medical research technology? Computing Reapplied believes in data as a catalyst to a deeper understanding. Our vision is simple: to allow medical research study enrollment and participation to be as easy as a touch of a button. Our technology increases participation amongst patients, creating a higher quality data pool for medical researchers to utilize in the pursuit of treating disease for years to come.</p>
                    <a href="#contactform-redirect"><ContactButton>Contact Us</ContactButton></a>
                </div>

            </div>

        </div>
    );
}

export default Vision;
