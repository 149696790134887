import React from "react";
// libraries
import { RouteComponentProps } from 'react-router-dom';
import blogdata from './blogdata.json';
// CSS
import '../../../css/blog.css';
import '../../../css/general.css';
import '../../../css/blog-responsive.css';

type Props = {};
type ComposedProps = Props & RouteComponentProps<{
    articleid: string;
}>;

export default class BlogArticlePage extends React.Component<ComposedProps> {
    constructor(props: ComposedProps) {
        super(props);
    }

    render() {

        const articleId = this.props.match.params.articleid;
        const articleInformation: any = blogdata.map(function (article) {
            if (article.id == articleId) {
                return article;
            }
        });
        return (
            <div className="blogarticle-container">
                <div className="blogarticlepage-container">
                    <div className="blogarticle-textimgcontainer">
                        <div className="articleimage-container">
                            <img className="blogarticle-image" src={articleInformation[articleId].image} alt="Blog post cover art" />
                        </div>
                        <h1 className="article-title">{articleInformation[articleId].name}</h1>
                        <div className="submissionInfo-container">
                            <h3 className="article-author">{articleInformation[articleId].author}</h3>
                            <h3 className="article-date">{articleInformation[articleId].date}</h3>
                        </div>
                    </div>
                    <div className="articletext-container">
                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5906360559999661"
                            crossOrigin="anonymous"></script>
                        <ins className="adsbygoogle"
                            data-ad-layout="in-article"
                            data-ad-format="fluid"
                            data-ad-client="ca-pub-5906360559999661"
                            data-ad-slot="7359670876"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                        <p className="article-content">{articleInformation[articleId].textContent}</p>
                    </div>
                </div>
            </div >
        );
    }
}