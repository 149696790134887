import React, { useState, useEffect } from "react";
// Images
import logo from "../images/icons/temp-logo.svg";
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import { useForm, ValidationError } from '@formspree/react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const FormTextField = withStyles((theme: Theme) => ({
    root: {
        color: "#002145",
        marginBottom: "20px",
    },
}))(TextField);
const FormButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "26rem",
        height: "3.0rem",
        fontSize: "1em",
        backgroundColor: "#ffffff",
        color: "#002145",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#002145",
            color: "#ffffff",
        },
        '@media only screen and (max-width: 760px)': {
            border: "solid 2px #002145",
            fontWeight: '500',
            width: "100%",
            height: "3.2rem",
        }
    },
}))(Button);

const ConsultButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "26rem",
        height: "3.0rem",
        fontSize: "1em",
        backgroundColor: "#002145",
        color: "white",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#ffffff",
            color: "#002145",
        },
        '@media only screen and (max-width: 760px)': {
            border: "solid 2px #002145",
            fontWeight: '500',
            width: "100%",
            height: "3.2rem",
        }
    },
}))(Button);

//https://formspree.io/forms/xpzkwbke/submissions
// TODO: I need email and passowrd from Sohrab for setting up formSpree account
// and url and email as well!

function SendForm() {
    interface state {
        name: string,
        email: string,
        company: string,
        message: string
    }
    //To Change form, enter a new code
    const [state, handleSubmit] = useForm("xvodzanq");
    if (state.succeeded) {
        return (<div className="sendform-container">
            <div className="sendform-content">
                <div className="sendform-text">
                    <h2>Let us tell you more.</h2>
                    <div className="text-p">
                        <p>We’ll email you to start off our conversation.</p>
                    </div>
                    <div className="form-image">
                        <img src={logo} alt="Computing Re Applied Logo" />
                    </div>
                </div>
                <div className="sendform-form">
                    <div className="success-notification">
                        <h2>Thanks For Reaching Out!</h2>
                    </div>
                </div>
            </div>
            {/* Sample Banner Image  */}
        </div >);
    } else {
        return (
            <div className="sendform-container">
                <div className="sendform-content">
                    <div className="sendform-text">
                        <h2>Let us tell you more.</h2>
                        <div className="text-p">
                            <p>We’ll email you to start off our conversation.</p>
                        </div>
                        <div className="form-image">
                            <img src={logo} alt="Computing Re Applied Logo" />
                        </div>
                    </div>
                    <div className="sendform-form">
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <FormTextField
                                className="form-field"
                                id="name"
                                type="name"
                                name="name"
                                label="Name" />
                            <FormTextField
                                className="form-field"
                                id="email"
                                type="email"
                                name="email"
                                label="Your Email" />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                            <FormTextField className="form-field" id="standard-basic" label="Company" />
                            <FormTextField
                                className="form-field"
                                id="message"
                                name="message"
                                label="Message"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                            />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />
                            <FormButton type="submit" disabled={state.submitting} variant="contained" color="primary">
                                Submit
                    </FormButton>
                        </form>
                        <div className="ortext-container">
                            <h4>or</h4>
                        </div>
                        <a target="_blank" href="https://outlook.office365.com/owa/calendar/ComputingReApplied@computingreapplied.com/bookings/">
                            <ConsultButton variant="contained" color="primary">
                                Schedule Free Consultation
                    </ConsultButton>
                        </a>
                    </div>
                </div>
                {/* Sample Banner Image  */}
            </div >
        );
    }
}

export default SendForm;

