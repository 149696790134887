import React from "react";
// Components
import Banner from './components/Banner';
import ThreeContentBlock from './components/ThreeContentBlock';
import Testimonials from './components/Testimonials';
import Vision from './components/Vision';
import Visualization from './components/Visualization';
import SendForm from '../SendForm';
import News from './components/News';
import Article from './components/Article';
import Video from './components/Video';
// CSS
import '../../css/home.css';
import '../../css/general.css';
import '../../css/home-responsive.css';


function Home() {

  return (
    <section className="home" id="home">
          <Banner />
          <Video/>
      <ThreeContentBlock />
      {/* <Testimonials /> */}
      <Vision />
      <Visualization />
      <SendForm />
      {/* <News /> */}
      <Article />
    </section >
  );
}

export default Home;
