import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors } from '../../../assets/Color/Color';

const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0, visibility: 'visible' },
    to: { opacity: 1, visibility: 'visible' },
  },
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  border: 'none',
  boxShadow: 'none',
  [theme.breakpoints.up('md')]: {
    maxWidth: '900px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  margin: '160px auto',
  overflow: 'hidden',
}));

const Icon = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ isVisible, delay }) => ({
  width: '80px',
  height: 'auto',
  marginBottom: '10px',
  opacity: 0,
  visibility: 'hidden',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
}));

const SectionHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ isVisible, delay }) => ({
  fontWeight: 'bold',
  fontFamily: 'Avenir-Medium',
  fontSize: '16px',
  color: colors.blueButton,
  marginBottom: '20px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  width: '120px',
  opacity: 0,
  visibility: 'hidden',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  animationDelay: `calc(${delay} + 0.5s)`,
  ...fadeIn,
}));

const ItemContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ theme, isVisible, delay }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '80px',
  opacity: 0,
  visibility: 'hidden',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
}));

const RowContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'isFirstVisible',
})<{ isVisible: boolean; isFirstVisible: boolean }>(({ theme, isFirstVisible }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '50px',
  padding: '40px 40px 10px 40px',
  position: 'relative',
  borderRadius: '15px',
  backgroundColor: isFirstVisible ? colors.backgroundBlue : 'transparent',
  transition: 'background-color 1s',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));

const HorizontalLine = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ theme, isVisible, delay }) => ({
  height: '2px',
  width: '20%',
  margin: '0 0 80px 0',
  backgroundSize: '15px 1px',
  backgroundImage:
    'repeating-linear-gradient(to right, #000 0%, #000 50%, transparent 50%, transparent 100%)',
  opacity: 0,
  visibility: 'hidden',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DottedLineVertical = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'delay',
})<{ isVisible: boolean; delay: string }>(({ isVisible, delay }) => ({
  height: '40%',
  width: 'auto',
  opacity: 0,
  visibility: 'hidden',
  animation: isVisible ? `fadeIn 1s forwards ${delay}` : 'none',
  ...fadeIn,
}));

const DottedLineContainer = styled('div')({
  position: 'absolute',
  top: '115px',
  left: '8%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const SmallIcon = styled(Icon)({
  width: '60px',
  height: 'auto',
});

const ResponsiveHeading = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  color: colors.blueButton,
  fontFamily: 'Sansation, sans-serif',
  fontWeight: 'bold',
  fontSize: '48px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '14px',
    marginTop: '100px',
  },
}));

const Desktop3 = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isFirstRowVisible, setIsFirstRowVisible] = useState(false);
  const [isSecondRowVisible, setIsSecondRowVisible] = useState(false);
  const [isThirdRowVisible, setIsThirdRowVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
          setHasAnimated(true);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasAnimated]);

  useEffect(() => {
    let timeoutIds: NodeJS.Timeout[] = [];
    if (isVisible) {
      timeoutIds.push(setTimeout(() => setIsFirstRowVisible(true), 500));
      timeoutIds.push(setTimeout(() => setIsSecondRowVisible(true), 5500));
      timeoutIds.push(setTimeout(() => setIsThirdRowVisible(true), 10500));
    }

    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, [isVisible]);

  return (
    <StyledPaper ref={ref}>
      <ResponsiveHeading variant="h3" gutterBottom align="center">
        How does it work?
      </ResponsiveHeading>
      <Box sx={{ my: 4 }}>
        <Grid container direction="column">
          {/* Top Row */}
          <RowContainer item xs={12} isVisible={isVisible} isFirstVisible={isFirstRowVisible}>
            <ItemContainer isVisible={isVisible} delay="0.5s">
              <Icon src="../../img/newhome/Desktop.svg" alt="Professional Dashboard" isVisible={isVisible} delay="0.5s" />
              <SectionHeading isVisible={isVisible} delay="0.5s">Professional Dashboard</SectionHeading>
            </ItemContainer>


            <DottedLineContainer>
              <DottedLineVertical src="../../img/newhome/DottedLine1.png" alt="Dotted line" isVisible={isVisible} delay="5s" />
            </DottedLineContainer>

            {isMdUp && <HorizontalLine isVisible={isVisible} delay="4.5s" />}
            <ItemContainer isVisible={isVisible} delay="4s">
              <Icon src="../../img/newhome/Row1Img1.svg" alt="Easy Team Collaboration" isVisible={isVisible} delay="4s" />
              <SectionHeading isVisible={isVisible} delay="4s">Easy Team Collaboration</SectionHeading>
            </ItemContainer>

            {isMdUp && <HorizontalLine isVisible={isVisible} delay="3.5s" />}
            <ItemContainer isVisible={isVisible} delay="3s">
              <Icon src="../../img/newhome/patientinteractionsettingsicon.svg" alt="Patient Interactions" isVisible={isVisible} delay="3s" />
              <SectionHeading isVisible={isVisible} delay="3s">Patient Interactions</SectionHeading>
            </ItemContainer>

            {isMdUp && <HorizontalLine isVisible={isVisible} delay="2.5s" />}
            <ItemContainer isVisible={isVisible} delay="2s">
              <Icon src="../../img/newhome/Row1Img3.svg" alt="API Integration" isVisible={isVisible} delay="2s" />
              <SectionHeading isVisible={isVisible} delay="2s">API Integration</SectionHeading>
            </ItemContainer>

            {isMdUp && <HorizontalLine isVisible={isVisible} delay="1.5s" />}
            <ItemContainer isVisible={isVisible} delay="1s">
              <Icon src="../../img/newhome/Row1Img4.svg" alt="Custom App Design" isVisible={isVisible} delay="1s" />
              <SectionHeading isVisible={isVisible} delay="1s">Custom App Design</SectionHeading>
            </ItemContainer>
          </RowContainer>

          {/* Middle Row */}

          <RowContainer item xs={12} isVisible={isVisible} isFirstVisible={isSecondRowVisible}>
            <ItemContainer isVisible={isVisible} delay="5.5s">
              <SmallIcon src="../../img/newhome/Phone1.svg" alt="Activity" isVisible={isVisible} delay="5.5s" />
              <SectionHeading isVisible={isVisible} delay="5.5s">Activity</SectionHeading>
            </ItemContainer>
            <DottedLineContainer>
              <DottedLineVertical src="../../img/newhome/DottedLine1.png" alt="Dotted line" isVisible={isVisible} delay="10s" />
            </DottedLineContainer>
            <HorizontalLine isVisible={isVisible} delay="9.5s" />
            <ItemContainer isVisible={isVisible} delay="9s">
              <Icon src="../../img/newhome/Row2Img1.svg" alt="Symptoms" isVisible={isVisible} delay="9s" />
              <SectionHeading isVisible={isVisible} delay="9s">Symptoms</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="8.5s" />
            <ItemContainer isVisible={isVisible} delay="8s">
              <Icon src="../../img/newhome/Row2Img2.svg" alt="Activity" isVisible={isVisible} delay="8s" />
              <SectionHeading isVisible={isVisible} delay="8s">Activity</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="7.5s" />
            <ItemContainer isVisible={isVisible} delay="7s">
              <Icon src="../../img/newhome/Row2Img3.svg" alt="Heart Rate" isVisible={isVisible} delay="7s" />
              <SectionHeading isVisible={isVisible} delay="7s">Heart Rate</SectionHeading>
            </ItemContainer>

            <HorizontalLine isVisible={isVisible} delay="6.5s" />
            <ItemContainer isVisible={isVisible} delay="6s">
              <Icon src="../../img/newhome/Row2Img4.svg" alt="Medication" isVisible={isVisible} delay="6s" />
              <SectionHeading isVisible={isVisible} delay="6s">Medication</SectionHeading>
            </ItemContainer>
          </RowContainer>

          {/* Bottom Row */}

          <RowContainer item xs={12} isVisible={isVisible} isFirstVisible={isThirdRowVisible}>
            <ItemContainer isVisible={isVisible} delay="10.5s">
              <Icon src="../../img/newhome/Skull.svg" alt="Data Analysis" isVisible={isVisible} delay="10.5s" />
              <SectionHeading isVisible={isVisible} delay="10.5s">Data Analysis</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="11s" />
            <ItemContainer isVisible={isVisible} delay="11.5s">
              <Icon src="../../img/newhome/Row3Img1.svg" alt="Complex Data Analysis" isVisible={isVisible} delay="11.5s" />
              <SectionHeading isVisible={isVisible} delay="11.5s">Complex Data Analysis</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="12s" />
            <ItemContainer isVisible={isVisible} delay="12.5s">
              <Icon src="../../img/newhome/Row3Img2.svg" alt="Share Globally" isVisible={isVisible} delay="12.5s" />
              <SectionHeading isVisible={isVisible} delay="12.5s">Share Globally with Institutions</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="13s" />
            <ItemContainer isVisible={isVisible} delay="13.5s">
              <Icon src="../../img/newhome/Row3Img3.svg" alt="Hospital Quality Improvements" isVisible={isVisible} delay="13.5s" />
              <SectionHeading isVisible={isVisible} delay="13.5s">Hospital Quality Improvements</SectionHeading>
            </ItemContainer>
            <HorizontalLine isVisible={isVisible} delay="14s" />
            <ItemContainer isVisible={isVisible} delay="14.5s">
              <Icon src="../../img/newhome/Row3Img4.svg" alt="Clean Data for AI Usage" isVisible={isVisible} delay="14.5s" />
              <SectionHeading isVisible={isVisible} delay="14.5s">Clean Data for AI Usage</SectionHeading>
            </ItemContainer>
          </RowContainer>
        </Grid>
      </Box>
    </StyledPaper>
  );
};

export default Desktop3;
