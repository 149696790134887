import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Grid, Box, Paper } from '@mui/material';
import { colors } from '../../../assets/Color/Color';

const BackgroundSection = styled(Paper)(({ theme }) => ({
  //marginTop: '60px',
  backgroundImage: 'url(../../img/newhome/LinesVactor1.svg)',
  backgroundSize: '100% 800px', // Ensures proper scaling
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '800px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    height: '650px',
  },
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: colors.blueButton,
  fontFamily: 'Sansation',
  fontWeight: 'bold',
  fontSize: '48px',
  lineHeight: 1.2,
  maxWidth: '580px',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem', // Adjust font size for small screens
    width: '75%',
    margin: `${theme.spacing(0)} auto ${theme.spacing(2)}`,
  },
  opacity: 0,
  transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  transform: 'translateY(20px)',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: colors.blueButton,
  fontFamily: 'Sansation, sans-serif',
  fontWeight: 'bold',
  fontSize: '24px', // changed from '1.5rem' to '24px'
  lineHeight: 1.5,
  maxWidth: '580px',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem', // Adjust font size for small screens
    width: '75%',
    margin: `${theme.spacing(0)} auto ${theme.spacing(5)}`,
  },
  opacity: 0,
  transition: 'opacity 1s ease-in-out 0.5s, transform 1s ease-in-out 0.5s',
  transform: 'translateY(20px)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors.blueButton,
  color: colors.whitefont,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: colors.blueButton,
    boxShadow: 'none',
  },
  borderRadius: 25,
  padding: theme.spacing(1, 4),
  fontFamily: 'Lato, sans-serif',
  fontSize: '24px', // changed from '1rem'
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2), // Space above the button on small screens
    marginBottom: theme.spacing(10),
    marginLeft: theme.spacing(5),
    fontSize: '1rem',
  },
  opacity: 0,
  transition: 'opacity 1s ease-in-out 1s, transform 1s ease-in-out 1s',
  transform: 'translateY(20px)',
}));

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  maxWidth: '700px',
  opacity: 0,
  transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  transform: 'translateY(20px)',
});

const MobileImageContainer = styled(Box)({
  position: 'absolute',
  opacity: 0,
  transition: 'opacity 1s ease-in-out 0.5s, transform 1s ease-in-out 0.5s',
  transform: 'translateY(20px)',
});

const Desktop1: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const handleButtonClick = () => {
    window.location.href = 'https://www.mymeddiary.app?auto=start';
  };

  return (
    <BackgroundSection ref={ref}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={5} sx={{
          textAlign: { xs: 'left', md: 'inherit' },
          position: 'relative',
          left: { md: '10%' }
        }}>
          <Heading style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(20px)' }}>
            A New Age of Scientific Research Data Collection
          </Heading>
          <SubHeading style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(20px)' }}>
            Improving Research Data Collection <br /> Accuracy while saving Time & Money
          </SubHeading>
          <StyledButton onClick={handleButtonClick} style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(20px)' }}>
            FREE to use today!
          </StyledButton>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center' }}>
          <ImageContainer style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(20px)' }}>
            <img src="../../img/newhome/macromoleculewebmockup.svg" alt="Laptop" style={{ width: '100%', height: 'auto' }} />
            <MobileImageContainer
              sx={{
                right: {
                  xs: '25px',  // Max width 100px for extra-small screens
                  sm: '-10px',
                  md: '2px'  // Max width 150px for small screens and up
                },
                bottom: {
                  xs: '-55px',  // Max width 100px for extra-small screens
                  sm: '-10px',
                  md: '-80px'  // Max width 150px for small screens and up
                }
              }}
              style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(20px)' }}
            >
              <Box
                component="img"
                src="../../img/newhome/logsymptoms.svg"
                alt="Mobile"
                sx={{
                  width: 'auto',
                  height: 'auto',
                  maxWidth: {
                    xs: '80px',  // Max width 100px for extra-small screens
                    sm: '100px',
                    md: '150px'  // Max width 150px for small screens and up
                  }
                }}
              />
            </MobileImageContainer>
          </ImageContainer>
        </Grid>
      </Grid>
    </BackgroundSection>
  );
};

export default Desktop1;
