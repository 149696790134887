
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/icons/crawebsitelogo82x82.svg";
import Hamburger from "./Hamburger";
import '../../css/header.css';
import '../../css/general.css';

function Header() {
    return (
        <div className="nav-container">
            <a className="logoname-container" href="/">
                <img className="logo" src={logo} alt="Logo" />
                <div style={{ display: "flex" }}>
                    <h3 className="company-name">Computing ReApplied</h3>
                    <h2 className="company-name-trademark" style={{ color: "white" }}>&trade;</h2>
                </div>
            </a>
            <div className="nav-buttons">
                <div className="menu">
                    <Link className="menunav-button" id='home-topnav' to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
                    <div className="dropmenu-container">
                        <span className="product-button">Product</span>
                        <div className="dropdown-menu">
                            {/*<Link className="dropdown-item" id='pricing' to="/pricing" ><a>Pricing</a></Link>*/}
                            <a target="_blank" href="https://www.mymeddiary.app/onboarding" className="dropdown-item" id='myMedDiary'>myMedDiary&reg;</a>
                            <a target="_blank" href="https://youtu.be/4pntWggtPto" className="dropdown-item" id='productVideo'>Product Video</a>
                        </div>
                    </div>
                    <a target="_blank" href="http://giving.uw.edu/OneHealthResearch" className="donate-button" id='donate' >Donate To Research</a>
                    <div className="dropmenu-container">
                        <span className="about-us-button">About Us</span>
                        <div className="dropdown-menu">
                            {/*<Link className="dropdown-item" id='team' to="/team" ><a>Team</a></Link>*/}
                            <Link className="dropdown-item" id='blog' to="/blog" ><a>Blog</a></Link>
                            <Link className="dropdown-item" id='press-release' to="/pressrelease" ><a>Press Release</a></Link>
                        </div>
                    </div>
                </div>
                <Hamburger />
            </div>
            <a className="initials-container" href="/">
                <h3 className="company-name-intials">C-RA</h3>
                <h2 style={{ color: "white" }}>&trade;</h2>
            </a>
        </div>
    );
}

export default Header;
