import React, { useEffect } from "react";
// Images
import search from '../../../images/icons/search.svg';
import handshake from '../../../images/icons/handshake.svg';
import quick from '../../../images/icons/quick.svg';
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Aos from "aos";
import "aos/dist/aos.css";

const CustomerButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "25rem",
        height: "3.0rem",
        fontSize: "1em",
        backgroundColor: "#ffffff",
        color: "#002145",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#002145",
            color: "#ffffff",
        },
        '@media only screen and (max-width: 580px)': {
            border: "solid 2px #002145",
            fontWeight: '500',
            width: "100%",
            height: "3.2rem",
            fontSize: "1.1em",
        }
    },
}))(Button);

function ThreeContentBlock() {
    useEffect(() => {
        Aos.init({ duration: 1100 });
    }, []);

    return (
        < div data-aos="fade-up" className="main-content" >
            {/* three column content block  */}
            < div className="block pad-top" >
                <div className="three-content pad-top-more">
                    {/* content block 1 */}
                    <div className="content-block">
                        <div className="block-image">
                            <img className="threecontent-image" src={search} alt="Search Icon" />
                        </div>
                        <div className="contentblockheader-container">
                            <h2>Enhanced Accuracy</h2>
                        </div>
                        <p className="block-text">Studies show that usage of data collection tools increases research study participation by 30%. We plan to enhance this number with our cloud based applications. Our first product allows patients and GI medical professionals to edit and view detailed nutritional breakdowns, specific locations of log in, and a user friendly symptom tracker.</p>
                    </div>
                    {/* content block 2 */}
                    <div className="content-block padding-tablet" id="content-block-two">
                        <div className="block-image">
                            <img className="threecontent-image" src={handshake} alt="Handshake Icon" />
                        </div>
                        <div className="contentblockheader-container">
                            <h2>Strong Partnerships</h2>
                        </div>
                        <p className="block-text">Our current alliances with both Microsoft and Seattle Children’s Hospital show our importance to leading industry experts. We, as a technical service provider, look forward to making further connections and partnerships that will push the medical research field forward. Read our customer success stories for more details.</p>
                    </div>
                    {/* content block 3 */}
                    <div className="content-block hide-tablet" id="content-block-three">
                        <div className="block-image">
                            <img className="threecontent-image" src={quick} alt="Efficiency Icon" />
                        </div>
                        <div className="contentblockheader-container">
                            <h2>Research Timeline</h2>
                        </div>
                        <p className="block-text">The implementation of our service reduces both study timelines and their related costs. This will not only allow for more studies to occur within a year, but will save researchers significant amounts of time between studies. This will bring efficicacy and simplicity to a process that has long been tedious and costly.</p>
                    </div>
                </div>
                <div className="customer-button" >
                    <a className="customerbutton-button" href="/blog">
                        <CustomerButton className="customerbutton-button" variant="contained" color="primary">
                            Success Stories
                    </CustomerButton>
                    </a>
                </div>
            </div >


            {/* end of main content */}
        </div >
    );
}

export default ThreeContentBlock;