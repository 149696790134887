import React from "react";
import "./UserTermsAndAgreement.css";

function UserTermsAndAgreement() {
  return (
    <section id="user-terms-container">
      <h1>COMPUTING REAPPLIED TERMS OF USE</h1>
      <p>
        We may provide a translated version of this Agreement, the Privacy
        Policy or any other operating rules, policies and procedures regarding
        the Services, in other languages for your convenience. Please note that
        the English language version of these documents is the version that
        governs your use of the Services and in the event of any conflict
        between the English language version and a translated version, the
        English language version will control.
      </p>
      <h2>AGREEMENT</h2>
      <p>
        These Terms of Use (the "Agreement") constitute a legally binding
        agreement by and between Computing ReApplied, Inc. (hereinafter,
        "Computing ReApplied") and you ("You" or "Your") concerning Your use of
        Computing ReApplied's website located at http://www.Computing
        ReApplied.com/ (the "Website") and Computing ReApplied mobile phone
        applications (the "Applications") and the services available through the
        Website and Applications (the "Services"). By using the Services, You
        represent and warrant that You have read and understand, and agree to be
        bound by, this Agreement and Computing ReApplied's Privacy Policy, which
        is incorporated herein by reference and made part of this Agreement. IF
        YOU DO NOT UNDERSTAND THIS AGREEMENT, OR DO NOT AGREE TO BE BOUND BY
        THESE TERMS, YOU MAY NOT USE THE SERVICES.
      </p>
      <h2>PRIVACY POLICY</h2>
      <p>
        By using the Services, You consent to the collection and use of certain
        information about You, as specified in the Privacy Policy. Use of
        information we collect now is subject to the Privacy Policy in effect at
        the time such information is used.
      </p>
      <h2>CHANGES TO AGREEMENT</h2>
      <p>
        COMPUTING REAPPLIED RESERVES THE RIGHT TO CHANGE THIS AGREEMENT AT ANY
        TIME UPON NOTICE TO YOU, TO BE GIVEN BY: (I) THE POSTING OF A NEW
        VERSION; AND/OR (II) A CHANGE NOTICE ON THE WEBSITE OR APPLICATION.
      </p>
      <p>
        IT IS YOUR RESPONSIBILITY TO REVIEW THIS AGREEMENT PERIODICALLY. You
        will be deemed to have agreed to any such modification or amendment by
        Your decision to continue using the Services following the date in which
        the modified or amended Agreement is posted.
      </p>
      <h2>ELIGIBILITY</h2>
      <p>
        BY USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU ARE AT LEAST
        18 YEARS OLD AND ARE OTHERWISE LEGALLY QUALIFIED TO ENTER INTO AND FORM
        CONTRACTS UNDER APPLICABLE LAW. This Agreement is void where prohibited.
      </p>
      <h2>LICENSE</h2>
      <p>
        Subject to Your compliance with the terms and conditions of this
        Agreement, Computing ReApplied grants You a non­exclusive, non­
        sublicensable, revocable, non­transferable license to use the Services
        through the Website or by downloading and installing our Applications.
        THE SERVICES ARE FOR YOUR PERSONAL AND NON­COMMERCIAL USE. The Services,
        including the Website and the Applications, or any portion thereof, may
        not be reproduced, duplicated, copied, modified, sold, resold,
        distributed, visited, or otherwise exploited for any commercial purpose
        without the express written consent of Computing ReApplied.
      </p>
      <p>
        Subject to Your compliance with the terms and conditions of this
        Agreement, Computing ReApplied grants You a non­exclusive, non­
        sublicensable, revocable, non­transferable license to use the Services
        through the Website or by downloading and installing our Applications.
        THE SERVICES ARE FOR YOUR PERSONAL AND NON­COMMERCIAL USE. The Services,
        including the Website and the Applications, or any portion thereof, may
        not be reproduced, duplicated, copied, modified, sold, resold,
        distributed, visited, or otherwise exploited for any commercial purpose
        without the express written consent of Computing ReApplied.
      </p>
      <p>
        Except as expressly set forth herein, this Agreement grants You no
        rights in or to the intellectual property of Computing ReApplied or any
        other party. The license granted in this section is conditioned on Your
        compliance with the terms and conditions of this Agreement. In the event
        that You breach any provision of this Agreement, Your rights under this
        section will immediately terminate.
      </p>
      <p>
        When accessing the Services through the Applications that were
        downloaded from an app store or app distribution platform, such as the
        Apple App Store or Google Play, (the “App Provider”), You acknowledge
        and agree that: (a) this Agreement are concluded between us, and not
        with the App Provider, and that we are solely responsible for the
        Applications (not the App Provider); (b) the App Provider has no
        obligation to furnish any maintenance and support services with respect
        to the Applications; (c) in the event of any failure of the Applications
        to conform to any applicable warranty, (i) you may notify the App
        Provider and the App Provider will refund the purchase price for the
        Applications to you (if applicable), (ii) to the maximum extent
        permitted by applicable law, the App Provider will have no other
        warranty obligation whatsoever with respect to the Applications, and
        (iii) any other claims, losses, liabilities, damages, costs or expenses
        attributable to any failure to conform to any warranty will be our
        responsibility; (d) the App Provider is not responsible for addressing
        any claims you have or any claims of any third party relating to the
        Applications or your possession and use of the Applications, including,
        but not limited to: (i) product liability claims; (ii) any claim that
        the app fails to conform to any applicable legal or regulatory
        requirement; and (iii) claims arising under consumer protection or
        similar legislation; (e) in the event of any third party claim that the
        Applications or your possession and use of that Applications infringes
        that third party’s intellectual property rights, we will be responsible
        for the investigation, defense, settlement and discharge of any such
        intellectual property infringement claim to the extent required by this
        Agreement; (f) the App Provider, and its subsidiaries, are third party
        beneficiaries of this Agreement as it relates to your license of the
        Applications, and that, upon your acceptance of the terms and conditions
        of this Agreement, the App Provider will have the right (and will be
        deemed to have accepted the right) to enforce this Agreement as related
        to your license of the Applications against you as a third party
        beneficiary thereof; and (g) you must also comply with all applicable
        third party terms of service when using the Applications.
      </p>
      <p>
        THE SERVICE DOES NOT PROVIDE PROFESSIONAL MEDICAL SERVICES OR ADVICE; NO
        DOCTOR­ PATIENT RELATIONSHIP.
      </p>
      <p>
        Computing ReApplied provides the Services for informational purposes
        only. THE SERVICES DO NOT CONTAIN OR CONSTITUTE, AND SHOULD NOT BE
        INTERPRETED AS, MEDICAL ADVICE OR OPINION. Computing ReApplied is not a
        medical professional, and Computing ReApplied does not provide medical
        services or render medical advice. The Services are not a substitute for
        the advice of a medical professional, and the information made available
        on or through the Services should not be relied upon when making medical
        decisions, or to diagnose or treat a medical or health condition. If you
        require medical advice or services, You should consult a medical
        professional. YOUR USE OF THE SERVICES DOES NOT CREATE A DOCTOR­PATIENT
        RELATIONSHIP BETWEEN YOU AND COMPUTING REAPPLIED.
      </p>
      <p>
        YOU HEREBY AGREE THAT, BEFORE USING THE SERVICES, YOU SHALL CONSULT YOUR
        PHYSICIAN, PARTICULARLY IF YOU ARE AT RISK FOR PROBLEMS RESULTING FROM
        EXERCISE OR CHANGES IN YOUR DIET.
      </p>
      <h2>FOOD DATABASE AND NUTRITIONAL INFORMATION</h2>
      <p>
        Computing ReApplied's food database contains a combination of
        nutritional information entered directly by Computing ReApplied and
        nutritional information entered by Computing ReApplied members ("Food
        Database"). Any Computing ReApplied member can contribute nutritional
        information to the Food Database, as well as edit existing nutritional
        information. Please be advised that nutritional information found in
        Computing ReApplied's Food Database has not been reviewed by persons
        with the expertise required to provide You with complete, accurate, or
        reliable information. COMPUTING REAPPLIED DOES NOT (I) GUARANTEE THE
        ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY NUTRITIONAL INFORMATION IN
        THE FOOD DATABASE; OR (II) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR
        THE ACCURACY OR RELIABILITY OF ANY SUCH NUTRITIONAL INFORMATION. UNDER
        NO CIRCUMSTANCES WILL COMPUTING REAPPLIED BE RESPONSIBLE FOR ANY LOSS OR
        DAMAGE RESULTING FROM YOUR RELIANCE ON NUTRITIONAL INFORMATION. You are
        solely responsible for ensuring that any nutritional information in the
        Food Database is accurate, complete and useful. Nutritional information
        in the Food Database may not be reproduced, duplicated, copied,
        modified, sold, resold, distributed, visited, or otherwise exploited for
        any commercial purpose without the express written consent of Computing
        ReApplied.
      </p>
      <h2>RELIANCE ON THIRD­PARTY CONTENT</h2>
      <p>
        Opinions, advice, statements, or other information, including, without
        limitation, food, nutrition and exercise data, made available by means
        of the Services by third parties, are those of their respective authors,
        and should not necessarily be relied on. Such authors are solely
        responsible for such content. COMPUTING REAPPLIED DOES NOT: (I)
        GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY THIRD­PARTY
        INFORMATION ON THE SERVICE; OR (II) ADOPT, ENDORSE OR ACCEPT
        RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE OR
        STATEMENT MADE BY A THIRD­PARTY BY MEANS OF THE SERVICES. UNDER NO
        CIRCUMSTANCES WILL COMPUTING REAPPLIED BE RESPONSIBLE FOR ANY LOSS OR
        DAMAGE RESULTING FROM YOUR RELIANCE ON INFORMATION OR OTHER CONTENT
        POSTED ON THE SERVICES OR TRANSMITTED TO OR BY ANY THIRD­PARTY.
      </p>
      <p>
        You also understand that by accessing and using the Services, You may
        encounter information, materials and subject matter that You or others
        may deem offensive, indecent, or objectionable. You agree to use the
        Services at Your sole risk and that Computing ReApplied and its
        affiliates, partners, suppliers and licensors shall have no liability to
        You for information, material or subject matter that is found to be
        offensive, indecent, or objectionable.
      </p>
      <h2>RISK ASSUMPTION</h2>
      <p>
        YOU KNOWINGLY AND FREELY ASSUME ALL RISK WHEN USING THE WEBSITE,
        APPLICATIONS AND SERVICES. YOU, ON BEHALF OF YOURSELF, YOUR PERSONAL
        REPRESENTATIVES AND YOUR HEIRS, HEREBY VOLUNTARILY AGREE TO RELEASE,
        WAIVE, DISCHARGE, HOLD HARMLESS, DEFEND AND INDEMNIFY COMPUTING
        REAPPLIED AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES,
        REPRESENTATIVES, SUBLICENSEES, SUCCESSORS, AND ASSIGNS FROM ANY AND ALL
        CLAIMS, ACTIONS OR LOSSES FOR BODILY INJURY, PROPERTY DAMAGE, WRONGFUL
        DEATH, EMOTIONAL DISTRESS, LOSS OF SERVICES OR OTHER DAMAGES OR HARM,
        WHETHER TO YOU OR TO THIRD PARTIES, WHICH MAY RESULT FROM YOUR USE OF
        THE SERVICES.
      </p>
      <h2>USER INFORMATION; PASSWORD PROTECTION</h2>
      <p>
        In connection with Your use of certain Services, You are required to
        complete a registration form. You represent and warrant that all user
        information You provide on the registration form or otherwise in
        connection with Your use of the Services will be current, complete and
        accurate, and that You will update that information as necessary to
        maintain its completeness and accuracy by visiting your personal
        profile. For additional information, see the section concerning "User
        Ability to Access, Update, and Correct Personal Information" in
        Computing ReApplied's Privacy Policy.
      </p>
      <p>
        You will also be asked to provide a user name and password in connection
        with Your use of certain of the Services. You are entirely responsible
        for maintaining the confidentiality of Your password. You may not use
        the account, user name, or password of any other Member at any time. You
        agree to notify Computing ReApplied immediately of any unauthorized use
        of Your account, user name, or password. Computing ReApplied shall not
        be liable for any loss that You incur as a result of someone else using
        Your password, either with or without Your knowledge. You may be held
        liable for any losses incurred by Computing ReApplied, its affiliates,
        officers, directors, employees, consultants, agents, and representatives
        due to someone else’s use of Your account or password.
      </p>
      <h2>PUBLIC PROFILES; INFORMATION PROVIDED BY MEMBERS</h2>
      <p>
        As part of registration, members must create public profiles, which may
        contain certain identifying information (such as age, location, total
        weight loss, ethnicity, marital status, religion, etc.). In addition,
        members have the option to post photographs, videos and other
        information (such as likes and dislikes) on their public profiles.
        Computing ReApplied relies on its members to provide current and
        accurate information, and Computing ReApplied does not, and cannot,
        investigate information contained in member public profiles.
        Accordingly, Computing ReApplied must assume that information contained
        in each member public profile is current and accurate. COMPUTING
        REAPPLIED DOES NOT REPRESENT, WARRANT OR GUARANTEE THE CURRENCY OR
        ACCURACY OF PUBLIC PROFILE INFORMATION, AND HEREBY DISCLAIMS ALL
        RESPONSIBILITY AND LIABILITY FOR ANY INFORMATION PROVIDED BY MEMBERS BY
        MEANS OF PUBLIC PROFILES OR OTHERWISE IN CONNECTION WITH THE SERVICES.
      </p>
      <h2>YOUR INTERACTIONS WITH OTHER MEMBERS</h2>
      <p>
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU
        ACKNOWLEDGE AND UNDERSTAND THAT COMPUTING REAPPLIED HAS NOT, AND DOES
        NOT, IN ANY WAY: (A) SCREEN ITS MEMBERS; (B) INQUIRE INTO THE
        BACKGROUNDS OF ITS MEMBERS; OR (C) REVIEW OR VERIFY THE STATEMENTS OF
        ITS MEMBERS, INCLUDING WITHOUT LIMITATION INFORMATION OR REPRESENTATIONS
        CONTAINED IN PUBLIC PROFILES. YOU HEREBY AGREE TO EXERCISE REASONABLE
        PRECAUTION IN ALL INTERACTIONS WITH OTHER MEMBERS, PARTICULARLY IF YOU
        DECIDE TO MEET ANOTHER MEMBER IN PERSON. COMPUTING REAPPLIED DOES NOT
        REPRESENT, WARRANT, ENDORSE OR GUARANTEE THE CONDUCT OF ITS MEMBERS OR
        THEIR COMPATIBILITY WITH YOU. IN NO EVENT SHALL COMPUTING REAPPLIED BE
        LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
        ARISING OUT OF OR RELATING TO ANY MEMBER’S CONDUCT IN CONNECTION WITH
        SUCH MEMBER’S USE OF THE SERVICES, INCLUDING, WITHOUT LIMITATION, BODILY
        INJURY, PROPERTY DAMAGE, WRONGFUL DEATH, EMOTIONAL DISTRESS, LOSS OF
        SERVICES OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS
        BETWEEN MEMBERS. YOU KNOWINGLY AND FREELY ASSUME ALL RISK WHEN USING THE
        WEBSITE, APPLICATIONS AND SERVICES.
      </p>
      <h2>MEMBER DISPUTES</h2>
      <p>
        Computing ReApplied reserves the right, but disclaims any perceived,
        implied or actual duty, to monitor disputes between members. You agree
        to hold Computing ReApplied harmless in connection with any dispute or
        claim You make against any other member.
      </p>
      <h2>PREMIUM VERSIONS OF THE WEBSITES AND/OR APPLICATIONS</h2>
      <p>
        Computing ReApplied offers certain premium versions of the Websites,
        Applications or Services (the “Premium Services”) in exchange for the
        payment of fees. The Premium Services provide you access to certain
        enhanced features and functionality (e.g., premium graphs and analysis,
        an ad­free browsing experience, etc.). By signing up for and using the
        Premium Services, you agree to pay any fees due for and incurred by you
        in your usage of the Premium Services.
      </p>
      <p>
        When you sign up for the Premium Services, you must designate and
        provide information about your preferred payment method (“Payment
        Method”). You may switch to a different Payment Method or update your
        information by visiting the “Settings” page within the Websites and
        Applications.
      </p>
      <p>
        You may elect to make payment for the Premium Services on a monthly or
        annual subscription basis. All fees due for the Premium Services are
        payable in advance. Fees due for the Premium Services will be billed
        automatically to the Payment Method at the start of the monthly or
        annual subscription period, as applicable, and will auto­renew until you
        elect to cancel your access to Premium Services. The subscription
        renewal fees will be the same as the initial charges unless you are
        otherwise notified in advance. You expressly authorize us to charge your
        Payment Method for the appropriate fees charged for the Premium Services
        and for any other purchases you elect to make via Computing ReApplied.
      </p>
      <p>
        We will administer and apply payments you transmit for the Premium
        Services via the online billing mechanisms of any of our affiliated
        websites and third party providers in a timely and commercially
        reasonable manner.
      </p>
      <p>
        We reserve the right to terminate your access to a Premium Service for
        any nonpayment of associated charges. If you want to discontinue a
        Premium Service, then you must cancel that Premium Service per
        applicable cancellation procedure set out here. The cancellation of a
        Premium Service will go into effect at the end of your current billing
        cycle, and you will have the same level of access to the Premium Service
        through the remainder of such billing cycle. For example, if you are
        billed on a monthly subscription basis and cancel during a given month,
        you will be charged for the entirety of that month and maintain access
        to the Premium Services through the end of that month. ALL PURCHASES OF
        PREMIUM SERVICES ARE FINAL, AND NO REFUND WILL BE GIVEN FOR UNUSED
        PORTIONS OF ANY PREMIUM SERVICES UPON TERMINATION OR CANCELATION.
      </p>
      <p>
        We reserve the right to increase fees charged for the Premium Services
        or to institute new fees at any time upon reasonable notice posted in
        advance on the Websites and/or Applications. Your only remedy regarding
        the increase of fees or imposition of new fees in connection with the
        Premium Services is to cancel the Premium Services per the cancellation
        procedure set out above.
      </p>
      <p>
        We reserve the right to increase fees charged for the Premium Services
        or to institute new fees at any time upon reasonable notice posted in
        advance on the Websites and/or Applications. Your only remedy regarding
        the increase of fees or imposition of new fees in connection with the
        Premium Services is to cancel the Premium Services per the cancellation
        procedure set out above.
      </p>
      <h2>CONSENT TO RECEIVE EMAIL FROM COMPUTING REAPPLIED</h2>
      <p>
        In providing the Services, You may receive periodic email communications
        regarding the Services, new product offers and information regarding the
        Services, which are part of the Services and which You cannot opt out of
        receiving. You may also receive periodic promotions and other offers or
        materials Computing ReApplied believes might be of interest to You. You
        can opt­out of receiving these promotional messages at any time by (a)
        following the unsubscribe instructions contained in each newsletter; or
        (b) changing the email preferences in Your account.
      </p>
      <h2>CONSENT TO RECEIVE EMAIL COMMUNICATIONS FROM MEMBERS</h2>
      <p>
        By finding and connecting with Your friends and other members, You may
        receive electronic communications, including email and instant messages
        from other Website members. You can manage the messages You receive from
        other members by changing the messaging preferences in Your account.
      </p>
      <h2>THIRD­PARTY WEBSITES</h2>
      <p>
        The Service includes links to websites of third parties ("Third­Party
        Websites"), some of whom may have established relationships with
        Computing ReApplied and some of whom may not. Computing ReApplied does
        not have control over the content and performance of Third­Party
        Websites. COMPUTING REAPPLIED HAS NOT REVIEWED, AND CANNOT REVIEW OR
        CONTROL, ALL OF THE MATERIAL, INCLUDING COMPUTER SOFTWARE OR OTHER GOODS
        OR SERVICES, MADE AVAILABLE ON OR THROUGH THIRD­PARTY WEBSITES.
        ACCORDINGLY, COMPUTING REAPPLIED DOES NOT REPRESENT, WARRANT OR ENDORSE
        ANY THIRD­PARTY WEBSITE, OR THE ACCURACY, CURRENCY, CONTENT, FITNESS,
        LAWFULNESS OR QUALITY OF THE INFORMATION MATERIAL, GOODS OR SERVICES
        AVAILABLE THROUGH THIRD­PARTY WEBSITES. COMPUTING REAPPLIED DISCLAIMS,
        AND YOU AGREE TO ASSUME, ALL RESPONSIBILITY AND LIABILITY FOR ANY
        DAMAGES OR OTHER HARM, WHETHER TO YOU OR TO THIRD PARTIES, RESULTING
        FROM YOUR USE OF THIRD­PARTY WEBSITES.
      </p>
      <p>
        YOU AGREE THAT, WHEN LINKING TO OR OTHERWISE ACCESSING OR USING A
        THIRD­PARTY WEBSITE, YOU ARE RESPONSIBLE FOR: (I) TAKING PRECAUTIONS AS
        NECESSARY TO PROTECT YOU AND YOUR COMPUTER SYSTEMS FROM VIRUSES, WORMS,
        TROJAN HORSES, MALICIOUS CODE AND OTHER HARMFUL OR DESTRUCTIVE CONTENT;
        (II) ANY DOWNLOADING, USE OR PURCHASE OF MATERIAL THAT IS OBSCENE,
        INDECENT, OFFENSIVE, OR OTHERWISE OBJECTIONABLE OR UNLAWFUL, OR THAT
        CONTAINS TECHNICAL INACCURACIES, TYPOGRAPHICAL MISTAKES AND OTHER
        ERRORS; (III) ANY DOWNLOADING, USE OR PURCHASE OF MATERIAL THAT VIOLATES
        THE PRIVACY OR PUBLICITY RIGHTS, OR INFRINGES THE INTELLECTUAL PROPERTY
        AND OTHER PROPRIETARY RIGHTS OF THIRD PARTIES, OR THAT IS SUBJECT TO
        ADDITIONAL TERMS AND CONDITIONS, STATED OR UNSTATED; (IV) ALL FINANCIAL
        CHARGES OR OTHER LIABILITIES TO THIRD PARTIES RESULTING FROM
        TRANSACTIONS OR OTHER ACTIVITIES; AND (V) READING AND UNDERSTANDING ANY
        TERMS OF USE OR PRIVACY POLICIES THAT APPLY TO THOSE THIRD­PARTY
        WEBSITES.
      </p>
      <h2>THIRD­PARTY SERVICES</h2>
      <p>
        Certain features, aspects, products and services offered through the
        Services are provided, in whole or in part, by third parties
        ("Third­Party Services" as provided by "Third­Party Service Providers").
        In order to use Third­Party Services, You may be required to enter into
        additional terms and conditions with Third­Party Service Providers. IF
        YOU DO NOT UNDERSTAND OR DO NOT AGREE TO BE BOUND BY THOSE ADDITIONAL
        TERMS AND CONDITIONS, YOU MAY NOT USE THE RELATED THIRD­PARTY SERVICES.
        In the event of any inconsistency between terms and conditions relating
        to Third­Party Services and the terms and conditions of this Agreement,
        those additional terms and conditions will control, although only with
        respect to such Third­Party Services. The providers of Third­Party
        Service Providers may collect and use certain information about you, as
        specified in the Third­Party Service Providers’ privacy policies. Prior
        to providing information to any Third­Party Service Provider, you should
        review their privacy policy. IF YOU DO NOT UNDERSTAND OR DO NOT AGREE TO
        THE TERMS OF A THIRD­PARTY SERVICE PROVIDER’S PRIVACY POLICY, YOU SHOULD
        NOT USE THE RELATED THIRD­PARTY SERVICES. COMPUTING REAPPLIED HEREBY
        DISCLAIMS ALL RESPONSIBILITY AND LIABILITY FOR ANY OF YOUR INFORMATION
        COLLECTED OR USED BY THIRD­PARTY SERVICE PROVIDERS.
      </p>
      <h2>USER CONTENT</h2>
      <p>
        "User Content" is any content, materials or information (e.g., any text,
        information, photos, images, video, and other content and material,
        including nutritional information contributed to the Food Database, and
        including symptoms logged by user) that You upload or post to, or
        transmit, display, perform or distribute by means of, the Services,
        whether in connection with Your use of Website, Application, or through
        the use of any Third Party Websites or Third Party Services or
        otherwise. You hereby grant Computing ReApplied and its officers,
        directors, employees, agents, affiliates, representatives, service
        providers, partners, sublicensees, successors, and assigns
        (collectively, the "Computing ReApplied Parties") a perpetual, fully
        paid­up, worldwide, sublicensable, irrevocable, assignable license to
        copy, distribute, publish, transmit, publicly display or perform, edit,
        modify, translate, reformat and otherwise use User Content in connection
        with the operation of the Services or any other similar services or
        related business, in any medium now existing or later devised, including
        without limitation in advertising and publicity. You further agree that
        this license includes the right for the Computing ReApplied Parties to
        publish, display or otherwise use and make available your User Content
        and possibly your name and/or any user name of yours in connection with
        their exercise of the license granted under this section. You agree to
        waive, and hereby waive, any claims arising from or relating to the
        exercise by the Computing ReApplied Parties of the rights granted under
        this section, including without limitation any claims relating to your
        rights of personal privacy and publicity. You will not be compensated
        for any exercise of the license granted under this section.
      </p>
      <p>
        You hereby represent and warrant that You own all rights, title and
        interest in and to User Content or are otherwise authorized to grant the
        rights provided the Computing ReApplied Parties under this section. You
        represent and warrant that, when using the Website, Applications and
        Services, You will obey the law and respect the intellectual property
        rights of others. Your use of the Website and Services is at all times
        governed by and subject to laws regarding copyright ownership and use of
        intellectual property generally. You agree not to upload, post,
        transmit, display, perform or distribute any content, information or
        other materials in violation of any third party’s copyrights,
        trademarks, or other intellectual property or proprietary rights. YOU
        SHALL BE SOLELY RESPONSIBLE FOR ANY VIOLATIONS OF ANY LAWS AND FOR ANY
        INFRINGEMENTS OF THIRD­PARTY RIGHTS CAUSED BY YOUR USE OF THE WEBSITE,
        APPLICATIONS AND SERVICES. YOUR BEAR THE SOLE BURDEN OF PROVING THAT
        CONTENT, INFORMATION OR OTHER MATERIALS DO NOT VIOLATE ANY LAWS OR
        THIRD­PARTY RIGHTS.
      </p>
      <p>
        Computing ReApplied reserves the right to (i) remove, suspend, edit or
        modify any User Content in its sole discretion, including without
        limitation any User Content at any time, without notice to you and for
        any reason (including, but not limited to, upon receipt of claims or
        allegations from third parties or authorities relating to such User
        Content or if Computing ReApplied is concerned that you may have
        violated these Terms of Use), or for no reason at all and (ii) to
        remove, suspend or block any User Content submissions. Computing
        ReApplied also reserves the right to access, read, preserve, and
        disclose any information as Computing ReApplied reasonably believes is
        necessary to (i) satisfy any applicable law, regulation, legal process
        or governmental request, (ii) enforce these Terms of Use, including
        investigation of potential violations hereof, (iii) detect, prevent, or
        otherwise address fraud, security or technical issues, (iv) respond to
        user support requests, or (v) protect the rights, property or safety of
        Computing ReApplied, its users and the public.
      </p>
      <h2>PUBLIC FORUMS</h2>
      <p>
        "Public Forum" is any area, site or feature offered as part of the
        Services (including without limitation public profiles, discussion
        forums, message boards, blogs, chat rooms, emails or instant messaging
        features) that enables You (a) to upload, submit, post, display,
        perform, distribute and/or view User Content, and/or (b) to communicate,
        share, or exchange User Content with other members or visitors. You
        acknowledge that Public Forums, and features contained therein, are for
        public and not private communications. You further acknowledge that
        anything You upload, submit, post, transmit, communicate, share or
        exchange by means of any Public Forum may be viewed on the Internet by
        the general public, and therefore, You have no expectation of privacy
        with regard to any such submission or posting. You are, and shall
        remain, solely responsible for the User Content you upload, submit,
        post, transmit, communicate, share or exchange by means of any Public
        Forum and for the consequences of submitting or posting same. You agree
        to abide by the Community Guidelines when transmitting or posting any
        User Content to any Public Forum. COMPUTING REAPPLIED DISCLAIMS ANY
        PERCEIVED, IMPLIED OR ACTUAL DUTY TO MONITOR PUBLIC FORUMS AND
        SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR INFORMATION
        PROVIDED THEREON.
      </p>
      <h2>YOUR RESPONSIBILITY FOR DEFAMATORY COMMENTS</h2>
      <p>
        You agree and understand that you may be held legally responsible for
        damages suffered by other members or third parties as the result of Your
        remarks, information, feedback or other content posted or made available
        on the Services that is deemed defamatory or otherwise legally
        actionable. Under the Federal Communications Decency Act of 1996,
        Computing ReApplied is not legally responsible, nor can it be held
        liable for damages of any kind, arising out of or in connection to any
        defamatory or otherwise legally actionable remarks, information,
        feedback or other content posted or made available on the Services.
      </p>
      <h2>OBJECTIONABLE CONTENT</h2>
      <p>
        You represent and warrant that you shall not use the Services to upload,
        post, transmit, display, perform or distribute any content, information
        or materials that: (a) are libelous, defamatory, abusive, or
        threatening, excessively violent, harassing, obscene, lewd, lascivious,
        filthy, or pornographic; (b) constitute child pornography; (c) solicit
        personal information; (d) incite, encourage or threaten physical harm
        against another; (e) promote or glorify racial intolerance, use hate
        and/or racist terms, or signify hate towards any person or group of
        people; (f) glamorize the use of hard core illegal substances and drugs;
        (g) advertise or otherwise solicit funds or constitute a solicitation
        for goods or services; (h) violate any provision of this Agreement or
        any other Computing ReApplied agreement, guidelines or policy; or (i) is
        generally offensive or in bad taste, as determined by Computing
        ReApplied (collectively, "Objectionable Content"). COMPUTING REAPPLIED
        DISCLAIMS ANY PERCEIVED, IMPLIED OR ACTUAL DUTY TO MONITOR THE CONTENTS
        OF THE SERVICES AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR
        LIABILITY FOR INFORMATION PROVIDED HEREON. Without limiting any of its
        other remedies, Computing ReApplied reserves the right to terminate Your
        use of the Services or Your uploading, posting, transmission, display,
        performance or distribution of Objectionable Content. Computing
        ReApplied, in its sole discretion, may delete any Objectionable Content
        from its servers.
      </p>
      <p>
        Computing ReApplied intends to cooperate fully with any law enforcement
        officials or agencies in the investigation of any violation of this
        Agreement or of any applicable laws.
      </p>
      <h2>PROHIBITED USES</h2>
      <p>
        Computing ReApplied imposes certain restrictions on Your use of the
        Services. You represent and warrant that you will not: (a) "stalk" or
        otherwise harass any person, or contact any person who has requested not
        to be contacted; (b) provide false, misleading or inaccurate information
        to Computing ReApplied or any other member; (c) impersonate, or
        otherwise misrepresent affiliation, connection or association with, any
        person or entity; (d) create more than one unique public profile; (e)
        harvest or otherwise collect information about Computing ReApplied
        users, including email addresses and phone numbers; (f) use or attempt
        to use any engine, software, tool, agent, or other device or mechanism
        (including without limitation browsers, spiders, robots, avatars, or
        intelligent agents) to harvest or otherwise collect information from the
        Website for any use, including without limitation use on third­party
        websites; (g) access content or data not intended for You, or log onto a
        server or account that You are not authorized to access; (h) attempt to
        probe, scan, or test the vulnerability of the Services, the Website, the
        Applications, or any associated system or network, or breach security or
        authentication measures without proper authorization; (i) interfere or
        attempt to interfere with the use of the Website, the Applications or
        the Services by any other user, host or network, including, without
        limitation by means of submitting a virus, overloading, "flooding,"
        "spamming," "mail bombing," or "crashing"; (j) use the Services to send
        unsolicited e­ mail, including without limitation promotions or
        advertisements for products or services; (k) forge any TCP/IP packet
        header or any part of the header information in any e­mail or in any
        uploading or posting to, or transmission, display, performance or
        distribution by means of, the Services; (l) post or transmit any
        unsolicited advertising, promotional materials, "junk mail", "spam,"
        "chain letters," "pyramid schemes" or any other form of solicitation or
        any non­resume information such as opinions or notices, commercial or
        otherwise; or (m) attempt to modify, reverse­engineer, decompile,
        disassemble or otherwise reduce or attempt to reduce to a
        human­perceivable form any of the source code used by the Computing
        ReApplied Parties in providing the Website or Applications. Any
        violation of this section may subject You to civil and/or criminal
        liability.
      </p>
      <h2>INTELLECTUAL PROPERTY</h2>
      <h3>a. Software</h3>
      <p>
        You acknowledge and agree that the Applications and all intellectual
        property rights associated therewith are, and shall remain, the property
        of Computing ReApplied. Furthermore, You acknowledge and agree that the
        source and object code of the Applications and the format, directories,
        queries, algorithms, structure and organization of the Applications are
        the intellectual property and proprietary and confidential information
        of Computing ReApplied and its affiliates, licensors and suppliers.
        Except as expressly stated in this Agreement, You are not granted any
        intellectual property rights in or to the Applications by implication,
        estoppel or other legal theory, and all rights in and to the
        Applications not expressly granted in this Agreement are hereby reserved
        and retained by Computing ReApplied.
      </p>
      <p>
        The Applications may utilize or include third party software that is
        subject to third party license terms ("Third Party Software"). You
        acknowledge and agree that Your right to use such Third Party Software
        as part of the Applications is subject to and governed by the terms and
        conditions of the third party license applicable to such Third Party
        Software. In the event of a conflict between the terms of this Agreement
        and the terms of such third party licenses, the terms of the third party
        licenses shall controlwith regard to Your use of the relevant Third
        Party Software.
      </p>
      <h3>b. Trademarks</h3>
      <p>
        Computing ReApplied, ComputingReApplied.com and the Computing ReApplied
        logo (collectively, the "Computing ReApplied Marks") are trademarks or
        registered trademarks of Computing ReApplied, LLC. Other trademarks,
        service marks, graphics, logos and domain names appearing on the
        Services may be the trademarks of third­parties. Neither Your use of the
        Services, nor this Agreement, grant You any right, title or interest in
        or to, or any license to reproduce or otherwise use, the Computing
        ReApplied Marks or any third­party trademarks, service marks, graphics,
        logos or domain names. You agree that any goodwill in the Computing
        ReApplied Marks generated as a result of Your use of the Services will
        inure to the benefit of Computing ReApplied, Inc., and You agree to
        assign, and hereby do assign, all such goodwill to Computing ReApplied,
        LLC.. You shall not at any time, nor shall You assist others to,
        challenge Computing ReApplied, LLC.’s right, title, or interest in or
        to, or the validity of, the Computing ReApplied Marks.
      </p>
      <h3>c. Copyrighted Materials; Copyright Notice</h3>
      <p>
        All content and other materials available through the Website and
        Services, including without limitation the Computing ReApplied logo,
        design, text, graphics, and other files, and the selection, arrangement
        and organization thereof, are either owned by Computing ReApplied, LLC.
        or are the property of Computing ReApplied’s licensors and suppliers.
        Except as explicitly provided, neither Your use of the Services nor this
        Agreement grant You any right, title or interest in or to any such
        materials.
      </p>
      <h3>d. DMCA Policy</h3>
      <p>
        If you have evidence, know, or have a good faith belief that your rights
        or the rights of a third party have been violated and you want Computing
        ReApplied to delete, edit, or disable the material in question, you must
        provide Computing ReApplied with all of the following information: (a) a
        physical or electronic signature of a person authorized to act on behalf
        of the owner of the exclusive right that is allegedly infringed; (b)
        identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works are covered by a single notification,
        a representative list of such works; (c) identification of the material
        that is claimed to be infringed or to be the subject of infringing
        activity and that is to be removed or access to which is to be disabled,
        and information reasonably sufficient to permit Computing ReApplied to
        locate the material; (d) information reasonably sufficient to permit
        Computing ReApplied to contact you, such as an address, telephone
        number, and if available, an electronic mail address at which you may be
        contacted; (e) a statement that you have a good faith belief that use of
        the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law; and (f) a statement that the
        information in the notification is accurate, and under penalty of
        perjury, that you are authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed. For this notification to be
        effective, you must provide it to Computing ReApplied’s designated agent
        at:
      </p>
      <p>Attn: Copyright Agent Computing ReApplied LLC.</p>
      <p>16509 NE 50th Way,</p>
      <p>Redmond, WA, 98052</p>
      <p>
        <a href="mailto:copyrightagent@computingreapplied.com">
          copyrightagent@computingreapplied.com
        </a>
      </p>
      <h2>DISCLAIMERS; LIMITATION OF LIABILITY</h2>
      <h3>a. NO WARRANTIES</h3>
      <p>
        COMPUTING REAPPLIED, ON BEHALF OF ITSELF AND ITS THIRD­PARTY SERVICE
        PROVIDERS, LICENSORS AND SUPPLIERS, HEREBY DISCLAIMS ALL WARRANTIES. THE
        WEBSITE, APPLICATIONS AND SERVICES ARE PROVIDED "AS IS" AND "AS
        AVAILABLE." TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPUTING REAPPLIED,
        ON BEHALF OF ITSELF AND ITS THIRD­PARTY SERVICE PROVIDERS, LICENSORS AND
        SUPPLIERS, EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR
        IMPLIED, REGARDING THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, ANY
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        OR NON­INFRINGEMENT. NEITHER COMPUTING REAPPLIED NOR ITS THIRD­PARTY
        SERVICE PROVIDERS, LICENSORS OR SUPPLIERS WARRANTS THAT THE WEBSITE,
        APPLICATIONS OR THE SERVICES WILL MEET YOUR REQUIREMENTS, THAT THE
        OPERATION OF THE WEBSITE, APPLICATIONS OR THE SERVICES WILL BE
        UNINTERRUPTED OR ERROR­FREE.
      </p>
      <h3>b. YOUR RESPONSIBILITY FOR LOSS OR DAMAGE; BACKUP OF DATA</h3>
      <p>
        YOU AGREE THAT YOUR USE OF THE WEBSITE, APPLICATIONS AND SERVICES IS AT
        YOUR SOLE RISK. YOU WILL NOT HOLD COMPUTING REAPPLIED OR ITS THIRD­PARTY
        SERVICE PROVIDERS, LICENSORS AND SUPPLIERS, AS APPLICABLE, RESPONSIBLE
        FOR ANY LOSS OR DAMAGE THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE
        SERVICES, INCLUDING WITHOUT LIMITATION ANY LOSS OR DAMAGE TO ANY OF YOUR
        COMPUTERS OR DATA. THE WEBSITE, APPLICATIONS AND SERVICES MAY CONTAIN
        BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS.
      </p>
      <p>
        IMPORTANTLY, YOU HEREBY ACKNOWLEDGE THAT A CATASTROPHIC SERVER FAILURE
        OR OTHER EVENT COULD RESULT IN THE LOSS OF ALL OF THE DATA RELATED TO
        YOUR ACCOUNT. YOU AGREE AND UNDERSTAND THAT IT IS YOUR RESPONSIBILITY TO
        BACKUP YOUR DATA TO YOUR PERSONAL COMPUTER OR EXTERNAL STORAGE DEVICE
        AND TO ENSURE SUCH BACKUPS ARE SECURE.
      </p>
      <h3>c. LIMITATION OF LIABILITY</h3>
      <p>
        THE LIABILITY OF COMPUTING REAPPLIED AND ITS THIRD­PARTY SERVICE
        PROVIDERS, LICENSORS AND SUPPLIERS IS LIMITED. TO THE MAXIMUM EXTENT
        PERMITTED BY LAW, IN NO EVENT SHALL COMPUTING REAPPLIED OR ITS
        THIRD­PARTY SERVICE PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE FOR
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST DATA
        OR CONFIDENTIAL OR OTHER INFORMATION, LOSS OF PRIVACY, COSTS OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, FAILURE TO MEET ANY DUTY
        INCLUDING WITHOUT LIMITATION OF GOOD FAITH OR OF REASONABLE CARE,
        NEGLIGENCE, OR OTHERWISE, REGARDLESS OF THE FORESEEABILITY OF THOSE
        DAMAGES OR OF ANY ADVICE OR NOTICE GIVEN TO COMPUTING REAPPLIED OR ITS
        THIRD­
      </p>
      <p>
        PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS ARISING OUT OF OR IN
        CONNECTION WITH YOUR USE OF THE WEBSITE, APPLICATIONS OR SERVICES. THIS
        LIMITATION SHALL APPLY REGARDLESS OF WHETHER THE DAMAGES ARISE OUT OF
        BREACH OF CONTRACT, TORT, OR ANY OTHER LEGAL THEORY OR FORM OF ACTION.
        ADDITIONALLY, THE MAXIMUM LIABILITY OF COMPUTING REAPPLIED AND ITS
        THIRD­PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS TO YOU UNDER ALL
        CIRCUMSTANCES WILL BE $50.00. YOU AGREE THAT THIS LIMITATION OF
        LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK AND IS A
        FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN COMPUTING
        REAPPLIED AND YOU. THE WEBSITE, APPLICATIONS AND SERVICES WOULD NOT BE
        PROVIDED WITHOUT SUCH LIMITATIONS.
      </p>
      <h3>d. APPLICATION</h3>
      <p>
        THE ABOVE DISCLAIMERS, WAIVERS AND LIMITATIONS DO NOT IN ANY WAY LIMIT
        ANY OTHER DISCLAIMER OF WARRANTIES OR ANY OTHER LIMITATION OF LIABILITY
        IN THIS AGREEMENT, ANY OTHER AGREEMENT BETWEEN YOU AND COMPUTING
        REAPPLIED OR BETWEEN YOU AND ANY OF COMPUTING REAPPLIED’S THIRD­PARTY
        SERVICE PROVIDERS, LICENSORS AND SUPPLIERS. SOME JURISDICTIONS MAY NOT
        ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES OR THE LIMITATION OF
        CERTAIN DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, WAIVERS AND
        LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. UNLESS LIMITED OR
        MODIFIED BY APPLICABLE LAW, THE FOREGOING DISCLAIMERS, WAIVERS AND
        LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED, EVEN IF ANY
        REMEDY FAILS ITS ESSENTIAL PURPOSE. COMPUTING REAPPLIED’S THIRD­PARTY
        SERVICE PROVIDERS LICENSORS AND SUPPLIERS ARE INTENDED THIRD­PARTY
        BENEFICIARIES OF THESE DISCLAIMERS, WAIVERS AND LIMITATIONS. NO ADVICE
        OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE
        SERVICES OR OTHERWISE SHALL ALTER ANY OF THE DISCLAIMERS OR LIMITATIONS
        STATED IN THIS SECTION.
      </p>
      <h2>YOUR REPRESENTATIONS AND WARRANTIES</h2>
      <p>
        You represent and warrant that Your use of the Website, Applications and
        Services will be in accordance with this Agreement and any other
        Computing ReApplied policies and guidelines, and with any applicable
        laws or regulations.
      </p>
      <h2>INDEMNITY BY YOU</h2>
      <p>
        Without limiting any indemnification provision of this Agreement, You
        agree to defend, indemnify and hold harmless Computing ReApplied and its
        officers, directors, employees, agents, affiliates, representatives,
        sublicensees, successors, assigns, and Third­Party Service Providers
        (collectively, the "Indemnified Parties") from and against any and all
        claims, actions, demands, causes of action and other proceedings
        (collectively, "Claims"), including but not limited to legal costs and
        fees, arising out of or relating to: (i) Your breach of this Agreement,
        including without limitation any representation or warranty contained in
        this Agreement; (ii) Your access to or use of the Website, Applications
        or Services; (iii) Your provision to Computing ReApplied or any of the
        Indemnified Parties of information or other data; (iv) Your violation or
        alleged violation of any foreign or domestic, federal, state or local
        law or regulation; or (v) Your violation or alleged violation of any
        third party’s copyrights, trademarks, or other intellectual property or
        proprietary rights.
      </p>
      <p>
        The Indemnified Parties will have the right, but not the obligation, to
        participate through counsel of their choice in any defense by You of any
        Claim as to which You are required to defend, indemnify or hold harmless
        the Indemnified Parties. You may not settle any Claim without the prior
        written consent of the concerned Indemnified Parties.
      </p>
      <h2>GOVERNING LAW; JURISDICTION AND VENUE</h2>
      <p>
        This Agreement, including without limitation this Agreement's
        interpretation, shall be treated as though this Agreement were executed
        and performed in San Francisco, California and shall be governed by and
        construed in accordance with the laws of the State of California without
        regard to its conflict of law principles. ANY CAUSE OF ACTION BY YOU
        ARISING OUT OF OR RELATING TO THE SERVICES, OR THIS AGREEMENT MUST BE
        INSTITUTED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION AROSE OR BE
        FOREVER WAIVED AND BARRED. ALL ACTIONS SHALL BE SUBJECT TO THE
        LIMITATIONS SET FORTH IN ABOVE. The language in this Agreement shall be
        interpreted in accordance with its fair meaning and not strictly for or
        against either party.
      </p>
      <h3>a. Requirement of Arbitration</h3>
      <p>
        You agree that any dispute, of any nature whatsoever, between You and
        Computing ReApplied arising out of or relating to this Agreement, shall
        be decided by neutral, binding arbitration before a representative of
        JAMS in San Francisco, California (unless You and Computing ReApplied
        mutually agree to a different arbitrator), who shall render an award in
        accordance with the substantive laws of California and JAMS’ Streamlined
        Arbitration Rules & Procedures. A final judgment or award by the
        arbitrator may then be duly entered and recorded by the prevailing party
        in the appropriate court as final judgment. The arbitrator shall award
        costs (including, without limitation, the JAMS’ fee and reasonable
        attorney’s fees) to the prevailing party.
      </p>
      <h3>b. Remedies in Aid of Arbitration; Equitable Relief</h3>
      <p>
        This agreement to arbitrate will not preclude You or Computing ReApplied
        from seeking provisional remedies in aid of arbitration, including
        without limitation orders to stay a court action, compel arbitration or
        confirm an arbitral award, from a court of competent jurisdiction.
        Furthermore, this agreement to arbitrate will not preclude You or
        Computing ReApplied from applying to a court of competent jurisdiction
        for a temporary restraining order, preliminary injunction, or other
        interim or conservatory relief, as necessary. THE PROPER VENUE FOR ANY
        ACTION PERMITTED UNDER THIS SUBSECTION REGARDING “EQUITABLE RELIEF” WILL
        BE THE FEDERAL AND STATE COURTS LOCATED IN SAN FRANCISCO, CALIFORNIA;
        THE PARTIES HEREBY WAIVE ANY OBJECTION TO THE VENUE AND PERSONAL
        JURISDICTION OF SUCH COURTS.
      </p>
      <h2>TERMINATION</h2>
      <h3>a. Termination; Survival</h3>
      <p>
        Either party may terminate this Agreement and its rights hereunder at
        any time, for any or no reason at all, by providing to the other party
        notice of its intention to do so in accordance with this Agreement. This
        Agreement shall automatically terminate in the event that You breach any
        of this Agreement’s representations, warranties or covenants. Such
        termination shall be automatic, and shall not require any action by
        Computing ReApplied. Upon termination, all rights, licenses and
        obligations created by this Agreement will terminate, except that
        Sections 1­4, 6­13, 16­30 will survive any termination of this
        Agreement.
      </p>
      <h3>b. Effect of Termination</h3>
      <p>
        Any termination of this Agreement automatically terminates all rights
        and licenses granted to You under this Agreement, including all rights
        to use the Website, Applications and Services. Subsequent to
        termination, Computing ReApplied reserves the right to exercise whatever
        means it deems necessary to prevent Your unauthorized use of the
        Website,, Applications and Services, including without limitation
        technological barriers such as IP blocking and direct contact with Your
        Internet Service Provider.
      </p>
      <h3>c. Legal Action</h3>
      <p>
        If Computing ReApplied, in Computing ReApplied’s discretion, takes legal
        action against You in connection with any actual or suspected breach of
        this Agreement, Computing ReApplied will be entitled to recover from You
        as part of such legal action, and You agree to pay, Computing
        ReApplied’s reasonable costs and attorneys’ fees incurred as a result of
        such legal action. The Computing ReApplied Parties will have no legal
        obligation or other liability to You or to any third party arising out
        of or relating to any termination of this Agreement.
      </p>
      <h2>NOTICES</h2>
      <p>
        All notices required or permitted to be given under this Agreement must
        be in writing. Computing ReApplied shall give any notice by email sent
        to the most recent email address, if any, provided by You to Computing
        ReApplied. You agree that any notice received from Computing ReApplied
        electronically satisfies any legal requirement that such notice be in
        writing. YOU BEAR THE SOLE RESPONSIBILITY OF ENSURING THAT YOUR EMAIL
        ADDRESS ON FILE WITH COMPUTING REAPPLIED IS ACCURATE AND CURRENT, AND
        NOTICE TO YOU SHALL BE DEEMED EFFECTIVE UPON THE SENDING BY COMPUTING
        REAPPLIED OF AN EMAIL TO THAT ADDRESS. You shall give any notice to
        Computing ReApplied by means of: (1) U.S. mail, postage prepaid, to
        Computing ReApplied LLC., 16509 NE 50th Way, Redmond, WA, 98052; or (2)
        email to: legal@computingreapplied.com. Notice to Computing ReApplied
        shall be effective upon receipt of notice by Computing ReApplied.
      </p>
      <h2>GENERAL</h2>
      <p>
        This Agreement constitutes the entire agreement between Computing
        ReApplied and You concerning Your use of the Services. This Agreement
        may only be modified by a written amendment signed by an authorized
        executive of Computing ReApplied or by the unilateral amendment of this
        Agreement by Computing ReApplied and by the posting by Computing
        ReApplied of such amended version. If any part of this Agreement is held
        invalid or unenforceable, that part will be construed to reflect the
        parties’ original intent, and the remaining portions will remain in full
        force and effect. A waiver by either party of any term or condition of
        this Agreement or any breach thereof, in any one instance, will not
        waive such term or condition or any subsequent breach thereof. Computing
        ReApplied may assign or transfer this Agreement at any time, with or
        without notice to You. This Agreement and all of Your rights and
        obligations hereunder will not be assignable or transferable by You
        without the prior written consent of Computing ReApplied. This Agreement
        will be binding upon and will inure to the benefit of the parties, their
        successors and permitted assigns. You and Computing ReApplied are
        independent contractors, and no agency, partnership, joint venture or
        employee­employer relationship is intended or created by this Agreement.
        Except for the Computing ReApplied Parties and the Indemnified Parties
        as and to the extent set forth in Sections 18, 21, 25 and 28(c), and
        Computing ReApplied’s licensors and suppliers as and to the extent
        expressly set forth in Section 23, there are no third­party
        beneficiaries to this Agreement. You acknowledge and agree that any
        actual or threatened breach of this Agreement or infringement of
        proprietary or other third party rights by You would cause irreparable
        injury to Computing ReApplied and Computing ReApplied’s licensors and
        suppliers, and would therefore entitle Computing ReApplied or Computing
        ReApplied’s licensors or suppliers, as the case may be, to injunctive
        relief. The headings in this Agreement are for the purpose of
        convenience only and shall not limit, enlarge, or affect any of the
        covenants, terms, conditions or provisions of this Agreement.
      </p>
      <h1>COMPUTING REAPPLIED PRIVACY POLICY</h1>
      <p>
        Computing ReApplied, LLC. (hereinafter, "Computing ReApplied") is
        committed to respecting the privacy rights of users of the Website and
        Computing ReApplied mobile phone applications (the "Applications").
        Computing ReApplied created this Privacy Policy to explain its
        information collection and use practices and the protection of Your
        information when You visit and use the Website and Applications. Any
        terms capitalized but not otherwise defined herein shall have the
        respective meanings set forth in the Computing ReApplied Terms of Use.
      </p>
      <p>
        This Privacy Policy is only applicable to the Website and Applications
        and does not apply to any websites or applications that are owned or
        operated by third parties ("Third­Party Websites/Applications"), which
        may have data collection, storage and use practices and policies that
        differ materially from this Privacy Policy. For additional information,
        see the section concerning Third­Party Websites/Applications, below.
      </p>
      <p>
        BY USING THE WEBSITE AND/OR APPLICATIONS, YOU REPRESENT AND WARRANT THAT
        YOU HAVE READ AND UNDERSTOOD, AND AGREE TO THE TERMS OF, THIS PRIVACY
        POLICY. IF YOU DO NOT UNDERSTAND OR DO NOT AGREE TO BE BOUND BY THIS
        PRIVACY POLICY, YOU MAY NOT USE THE WEBSITE AND/OR APPLICATION.
      </p>
      <h2>INFORMATION COLLECTION PRACTICES</h2>
      <h3>a. Traffic Data</h3>
      <p>
        Computing ReApplied automatically gathers information of the sort that
        browsers automatically make available, including: (i) IP addresses; (ii)
        domain servers; (iii) types of computers accessing the Website; and (iv)
        types of Web browsers accessing the Website (collectively "Traffic
        Data"). Traffic Data is anonymous information that does not personally
        identify You.
      </p>
      <h3>b. Cookies</h3>
      <p>
        A "Cookie" is a string of information that a website stores on a user’s
        computer, and that the user's browser provides to the website each time
        the user submits a query to the website. The purpose of a Cookie is to
        identify the user as a unique user of the Website. Computing ReApplied
        uses Cookies to customize Your experience on the Website to Your
        interests, to ensure that You do not see the same advertisements or
        informational messages repeatedly, and to store Your password so You do
        not have to re­enter it each time You visit the Website. In addition,
        Computing ReApplied may use Cookies, and other methods, to store and
        retrieve data from Your web browser. For additional information on
        Computing ReApplied’s uses of Cookies, see the section concerning
        Information Use and Disclosure Practices/Traffic Data and Information
        Gathered Using Cookies, below.
      </p>
      <p>
        IF YOU DO NOT WISH TO HAVE COOKIES PLACED ON YOUR COMPUTER, YOU SHOULD
        SET YOUR BROWSERS TO REFUSE COOKIES BEFORE ACCESSING THE WEBSITE, WITH
        THE UNDERSTANDING THAT CERTAIN OF THE SERVICES AND CERTAIN FEATURES OF
        THE WEBSITE MAY NOT FUNCTION PROPERLY WITHOUT THE AID OF COOKIES.
      </p>
      <h3>c. Web Beacons</h3>
      <p>
        "Web Beacons" (also known as clear gifs, pixel tags or web bugs) are
        tiny graphics with a unique identifier, similar in function to Cookies,
        and are used to track the online movements of web users or to access
        Cookies. Unlike Cookies which are stored on the user’s computer hard
        drive, Web Beacons are embedded invisibly on web pages (or in e­mail).
        Web Beacons may be used to deliver or communicate with Cookies, to count
        users who have visited certain pages and to understand usage patterns.
        For additional information on Computing ReApplied’s uses of Web Beacons,
        see the section concerning Information Use and Disclosure
        Practices/Traffic Data and Information Gathered Using Cookies & Web
        Beacons, below.
      </p>
      <h3>d. Mobile Device Data</h3>
      <p>
        When users download, install and use the Applications on their mobile
        phone or other wireless telecommunications device, Computing ReApplied
        automatically gathers information, including without limitation, carrier
        providers, a unique device identifier, geo­location information (if
        allowed by the user), the types of mobile devices accessing the
        Application, and the types of operating systems accessing the
        Applications (collectively, "Mobile Device Data"). A unique device
        identifier is a string of alphanumeric characters (similar to a serial
        number) used to uniquely identify and distinguish each mobile phone or
        other wireless communications device. Location services can be enabled
        or disabled at anytime, through the mobile device settings. Computing
        ReApplied uses Mobile Device Data to understand usage patterns and to
        improve the Applications. For additional information on Computing
        ReApplied’s uses of Mobile Device Data, see the section concerning
        Information Use and Disclosure Practices/Traffic Data and Information
        Gathered Using Cookies, Web Beacons, and Mobile Device Data, below.
      </p>
      <h3>e. Ad Serving and Usage Analytics</h3>
      <p>
        Computing ReApplied uses third­parties for advertising and usage
        analytics for the Website and Applications. These third­parties may
        place Cookies on Your machine, use Web Beacons, gather Personal
        Information (as defined below), Traffic Data and Mobile Device Data, and
        log data to collect traffic and activity data in order to deliver
        relevant metrics, content, and advertising. We do not share your
        non­public Personal Information with these parties, but we may share
        anonymized or aggregated information with them to improve the relevancy
        of the ads you see at Computing ReApplied. The collection of this
        information by third­parties is subject to such third­parties’ privacy
        policies. For additional information, see the section concerning
        Third­Party Websites/Applications, below. With regards to advertising on
        the Website, to learn more about behavioral advertising practices or to
        opt­out of this type of advertising, visit
        <a href="http://www.networkadvertising.org/choices">
          http://www.networkadvertising.org/choices
        </a>
        ,
        <a href="http://www.aboutads.info/choices">
          http://www.aboutads.info/choices
        </a>
        , and
        <a href="http://opt.jumptap.com/optout/opt?jt">
          http://opt.jumptap.com/optout/opt?jt
        </a>
        .
      </p>
      <h3>f. Personal Information</h3>
      <p>
        You will be asked to provide Computing ReApplied certain information
        related to You ("Personal Information"). Personal Information includes,
        without limitation: (1) "Contact Data" (personally identifiable
        information about You, such as Your name and email address, as well as
        Your friends and contacts, if you enable access to Your contacts and
        address book information); and (2) "Demographic Data" (personal
        information about You (but doesn’t specifically identify You), such as
        Your gender, birthday, zip code, country, height, weight, lifestyle,
        exercise frequency, symptom logging frequency); and (3) "Fitness Data"
        (information about Your use of the Services (but doesn’t specifically
        identify You), such as Your caloric intake, nutritional statistics,
        fitness activity, and weight loss/gain); and (3) "Symptom Data"
        (information about Your use of the Services (but doesn’t specifically
        identify You), such (but not limited to) as Your symptoms, location,
        time of symptom logging). The Personal Information you provide is used
        for such purposes as allowing you to set up a user account and profile,
        providing the Website and/or Applications and related services,
        monitoring and improving the content and usage of the Website and/or
        Applications, interacting with the Website and/or Applications and with
        your friends and other members, to find and connect with Your friends
        and other members (when instructed by You), customizing the advertising
        and content you see, communicating with you about new features and other
        news, and any other purpose for which the information was collected. We
        may also draw upon this Personal Information in order to adapt the
        Website and/or Applications to your needs, to research the effectiveness
        of our Website and/or Applications, and to develop new tools for our
        members.
      </p>
      <p>
        You are under no obligation to provide Personal Information, however,
        not providing this information may prevent You from using certain
        features of the Services.
      </p>
      <h3>g. Third Party Services</h3>
      <p>
        If you create an account using Your login/ID from a Third Party Service,
        like Facebook, Computing ReApplied will use Your login/ID to access and
        collect the information that Your privacy settings on that Third Party
        Service permits Computing ReApplied to access so that Computing
        ReApplied can create Your account and connect You with Your friends.
      </p>
      <h3>h. Information You Make Public</h3>
      <p>
        Certain portions of the Website and Applications are open to any viewer,
        such as our community forums and Your personal profile. Any information
        You post in these locations may be available and accessible to other
        members of the Website. In addition, the Website contains features that
        permit You to upload, post, transmit, display, perform or distribute
        content, information or other data, including Your Personal Information.
        Any information that You choose to disclose by means of such features
        becomes public information. You should exercise caution when deciding to
        disclose Your Personal Information by means of such features, and You
        agree to assume all responsibility for doing so.
      </p>
      <h3>i. Transfer of HealthKit Data</h3>
      <p>
        You can choose to connect and share your Computing ReApplied information
        with HealthKit and your HealthKit information with Computing ReApplied.
        The information you provide to HealthKit is then governed by the Apple
        Terms and Conditions and Privacy Policy. The unique information you
        choose to send from HealthKit is not used by Computing ReApplied for
        marketing and advertising or transferred by Computing ReApplied to third
        parties for marketing and advertising. In the future, it may be
        accessible by third party medical professionals who will follow HIPAA
        protocol (ensuring your privacy), and You are agreeing to allow
        Computing ReApplied to share this data with them for medical research
        purposes.
      </p>
      <h2>INFORMATION USE AND DISCLOSURE PRACTICES</h2>
      <h3>
        a. Traffic Data and Information Gathered Using Cookies, Web Beacons &
        Mobile Device Data.
      </h3>
      <p>
        Computing ReApplied analyzes Traffic Data and information gathered using
        Cookies, Web Beacons and Mobile Device Data to help Computing ReApplied
        better understand who is using the Website and/or Applications and how
        they are using the Website and/or Applications. By identifying patterns
        and trends in usage, Computing ReApplied is able to better design the
        Website and Applications to improve Your experience, and to serve You
        more relevant and interesting content and advertisements. From time to
        time, Computing ReApplied may release Traffic Data and information
        gathered using Cookies, Web Beacons and Mobile Device Data in the
        aggregate, such as by publishing a report on trends in the usage of the
        Website. In addition, Computing ReApplied may share Traffic Data and
        Mobile Device Data and information gathered using Cookies and Web
        Beacons with: (1) third­party analytics companies so that such
        third­parties may help Computing ReApplied better understand who is
        using the Website and/or Applications and how they are using the Website
        and/or Applications; (2) advertisers and marketing partners so that such
        third­parties may provide You with advertisements tailored to Your
        interests; and (3) service providers so that we can personalize, provide
        and improve our Services to Your use and interests.
      </p>
      <h3>b. Personal Information</h3>
      <ol>
        <li>Contact Data</li>
        <p>
          Computing ReApplied uses Your Contact Data to send You information
          about Computing ReApplied and Computing ReApplied’s products and
          services, and to contact You when necessary in connection with the
          Services. Computing ReApplied uses Your Contact Data (when instructed
          by You) to connect You with Your friends and other members with whom
          You want to connect with, according to the preferences set in Your
          account.
        </p>
        <li>Demographic Data</li>
        <p>
          Computing ReApplied uses Your Demographic Data to customize and tailor
          Your experience on the Website and/or Applications. As with Traffic
          Data and Mobile Device Data and information gathered using Cookies and
          Web Beacons, from time to time Computing ReApplied may release or
          share Demographic Data with third parties in anonymous form and/or in
          the aggregate, for industry analysis, research, demographic profiling
          and other similar purposes. In addition, Computing ReApplied shares
          anonymized Demographic Data with its advertisers and marketing
          partners so that such third­parties may provide You with
          advertisements tailored to Your interests. To opt­out of sharing your
          anonymized and Demographic Data with our advertising and marketing
          partners, visit{" "}
          <a href="http://www.networkadvertising.org/choices">
            http://www.networkadvertising.org/choices
          </a>
          ,
          <a href="http://www.aboutads.info/choices">
            http://www.aboutads.info/choices
          </a>
          , and email us at
          <a href="mailto:contact-us@computingreapplied.com">
            contact-us@computingreapplied.com
          </a>
          .
        </p>
        <li>Fitness Data</li>
        <p>
          Computing ReApplied uses Your Fitness Data to provide, personalize and
          improve Your experience on the Website and/or Applications. As with
          Demographic Data and other data gathered through the Service,
          Computing ReApplied may release or share Fitness Data with third
          parties in anonymous form and/or in the aggregate, for statistical
          analysis, research, demographic profiling and other similar purposes.
          In addition, Computing ReApplied may share your Fitness Data along
          with some of Your Demographic Data, in anonymous form and in the
          aggregate, with other Computing ReApplied users so that You and other
          users can compare their own personal fitness, health and wellness
          situation relative to the entire Computing ReApplied community. In the
          future, it may be accessible by third party medical professionals who
          will follow HIPAA protocol (ensuring your privacy), and You are
          agreeing to allow Computing ReApplied to share this data with them for
          medical research purposes.
        </p>
        <li>Symptom Data</li>
        <p>
          Computing ReApplied uses Your Symptom Data to provide, personalize and
          improve Your experience on the Website and/or Applications. As with
          Demographic Data and other data gathered through the Service,
          Computing ReApplied may release or share Symptom Data with third
          parties in anonymous form and/or in the aggregate, for statistical
          analysis, research, demographic profiling and other similar purposes.
          In addition, Computing ReApplied may share your Symptom Data along
          with some of Your Demographic Data, in anonymous form and in the
          aggregate, with other Computing ReApplied users so that You and other
          users can compare their own personal fitness, health and wellness
          situation relative to the entire Computing ReApplied community. In the
          future, it may be accessible by third party medical professionals who
          will follow HIPAA protocol (ensuring your privacy), and You are
          agreeing to allow Computing ReApplied to share this data with them for
          medical research purposes.
        </p>
      </ol>
      <h3>c. Other Disclosure Practices</h3>
      <p>
        Except as otherwise provided in this Privacy Policy, and when You
        otherwise give permission or under the following circumstances,
        Computing ReApplied will not share Your Personal Information with third
        parties.
      </p>
      <ul>
        <li>Disclosure in Connection with Services</li>
        <p>
          Computing ReApplied discloses Personal Information to those who help
          it provide services, including those who perform technical,
          administrative and data processing tasks such as hosting, billing,
          fulfillment, and data storage and security.
        </p>
        <li>By Law or to Protect Rights</li>
        <p>
          Computing ReApplied discloses Personal Information when required to do
          so by law, or in response to a subpoena or court order, or when
          Computing ReApplied believes in its sole discretion that disclosure is
          reasonably necessary to protect the property or rights of Computing
          ReApplied, third­parties or the public at large.
        </p>
        <li>Business Transfers; Bankruptcy</li>
        <p>
          Computing ReApplied reserves the right to transfer all Personal
          Information in its possession to a successor organization in the event
          of a merger, acquisition, or bankruptcy or other sale of all or a
          portion of Computing ReApplied’ assets. Other than to the extent
          ordered by a bankruptcy or other court, the use and disclosure of all
          transferred Personal Information will be subject to this Privacy
          Policy, or to a new privacy policy if You are given notice of that new
          privacy policy and an opportunity to affirmatively opt­out of it.
          Personal Information submitted or collected after a transfer, however,
          may be subject to a new privacy policy adopted by Computing
          ReApplied’s successor organization.
        </p>
      </ul>
      <h2>EMAIL COMMUNICATIONS FROM COMPUTING REAPPLIED</h2>
      <p>
        In providing the Services, You may receive periodic email communications
        from Computing ReApplied regarding the Services, such as new product
        offers and other information regarding the Services, which are part of
        the Services and which You cannot opt out of receiving. You may also
        receive periodic promotions and other offers or materials Computing
        ReApplied believes might be of interest to You. You can opt­out of
        receiving these promotional messages at any time by (a) following the
        unsubscribe instructions contained in each message; or (b) changing the
        messaging preferences in Your account.
      </p>
      <h2>EMAIL COMMUNICATIONS FROM MEMBERS</h2>
      <p>
        By finding and connecting with Your friends and other members, You may
        receive electronic communications, including email and personal messages
        from other Computing ReApplied members. You can manage the messages You
        receive from other members by changing the messaging preferences in Your
        account.
      </p>
      <h2>SECURITY OF PERSONAL INFORMATION</h2>
      <p>
        Computing ReApplied has implemented and maintains reasonable security
        procedures and practices designed to protect against the unauthorized
        access, use, modification, destruction or disclosure of Your Personal
        Information, however, despite these efforts, no security measures are
        perfect or impenetrable and no method of data transmission can be
        guaranteed against any interception or other type of misuse. In the
        event that Your Personal Information is compromised as a result of a
        breach of security, Computing ReApplied will promptly notify You if Your
        Personal Information has been compromised as required by applicable law.
      </p>
      <h2>USER ABILITY TO ACCESS, UPDATE, AND CORRECT PERSONAL INFORMATION</h2>
      <p>
        If You have an account, You can access and modify Your Personal
        Information through Your account, at any time. If you completely delete
        all such information You will not be able to access or use the Website
        and/or Applications correctly. If You would like Computing ReApplied to
        delete Your Personal Information from its system, please contact us at
        removal@ComputingReApplied.com with a request that we delete Your
        Personal Information from its database. Computing ReApplied will use
        commercially reasonable efforts to honor Your request; however,
        Computing ReApplied may retain an archived copy of Your records as
        required by law or for other legitimate business purposes.
      </p>
      <p>
        Computing ReApplied wants Your Personal Information to be complete and
        accurate. By using the Website and/or Applications, You represent and
        warrant that all information You provide on any registration form or
        otherwise in connection with Your use of the Website and/or Applications
        and Services will be complete and accurate, and that You will update
        that information as necessary to maintain its completeness and accuracy.
        To confirm the completeness and accuracy of, or make changes to, Your
        Personal Information, please visit Your personal profile.
      </p>
      <h2>THIRD­PARTY WEBSITES/APPLICATIONS</h2>
      <p>
        The Website and Applications contain links to Third­Party
        Websites/Applications. Computing ReApplied does not own or control these
        Third­Party Websites/Applications. Computing ReApplied is not
        responsible for the privacy and security practices and policies of those
        Third­Party Websites/Applications and this Privacy Policy does not apply
        to any Third­Party Websites/Applications, except with respect to
        Personal Information provided directly to them by Computing ReApplied.
        Before visiting or providing Personal Information to a Third­Party
        Website, You should inform Yourself of the privacy policies and
        practices (if any) of that Third­Party Website, and should take those
        steps necessary to, in Your discretion, protect Your privacy.
      </p>
      <h2>UPDATES AND CHANGES TO PRIVACY POLICY</h2>
      <p>
        Computing ReApplied reserves the right to, update, change or modify this
        Privacy Policy. Any material changes to this policy will be posted on
        the Website and/or Applications (or You may be notified by email or
        other notification), and will indicate when such changes will become
        effective. You will be deemed to have agreed to any such modification or
        amendment by Your decision to continue using the Website and/or
        Application following the date in which the modified or amended Privacy
        Policy is posted on the Website. Use of information we collect now is
        subject to the Privacy Policy in effect at the time such information is
        used.
      </p>
      <h2>CHILDREN'S PRIVACY</h2>
      <p>
        Computing ReApplied does not solicit or knowingly collect personally
        identifiable information from children under the age of 13. If Computing
        ReApplied obtains actual knowledge that it has collected personally
        identifiable information from a child under the age of 13, Computing
        ReApplied will promptly delete such information from its database.
      </p>
      <h2>INTERNATIONAL USERS</h2>
      <p>
        If you are located outside the United States and choose to provide Your
        information to Computing ReApplied, Your information will be transferred
        to the United States and processed there (or any other country where
        Computing ReApplied operates). By providing Your information and using
        the Website and/or Applications, You agree and consent to the
        collection, transfer, use, storage and disclosure of Your information as
        described in this Privacy Policy.
      </p>
      <h2>EFFECTIVE DATE: April 4, 2020</h2>
    </section>
  );
}

export default UserTermsAndAgreement;
