import React from "react";
// Components
import BlogPost from './BlogPost';
//Data
import blogdata from './blogdata.json';

const data = blogdata.map(function (data, i) {
    return (
        <BlogPost articleData={data} key={i} />
    )
});

function BlogList() {
    return (
        <div className="bloglist-container">
            {data}
        </div>
    );
}

export default BlogList;
