

import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../../assets/Color/Color';

const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateX(-10px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  },
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  border: 'none',
  boxShadow: 'none',
  margin: '100px auto',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    display: 'none', // Hide on screens larger than 'sm'
  },
}));

const Icon = styled('img')(({ isVisible, delay }: { isVisible: boolean, delay: string }) => ({
  width: '60px',
  height: 'auto',
  marginBottom: '10px',
  opacity: 0,
  animation: isVisible ? `fadeIn 1s ${delay} forwards` : 'none',
  ...fadeIn,
}));

const Icon2 = styled('img')(({ isVisible, delay }: { isVisible: boolean, delay: string }) => ({
  width: '45px',
  height: 'auto',
  marginBottom: '10px',
  opacity: 0,
  animation: isVisible ? `fadeIn 1s ${delay} forwards` : 'none',
  ...fadeIn,
}));


const Icon3 = styled('img')(({ isVisible, delay }: { isVisible: boolean, delay: string }) => ({
  width: '60px',
  height: 'auto',
  marginBottom: '10px',
  opacity: 0,
  animation: isVisible ? `fadeIn 1s ${delay} forwards` : 'none',
  ...fadeIn,
}));


const SectionHeading = styled(Typography)(({ isVisible, delay }: { isVisible: boolean, delay: string }) => ({
  fontWeight: 'bold',
  fontFamily: 'Sansation',
  color: '#003366',
  opacity: 0,
  animation: isVisible ? `fadeIn 1s ${delay} forwards` : 'none',
  ...fadeIn,
}));

const ItemContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '25%',
  flex: `0 0 calc(100% / 4)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1),
}));

const ConnectiveImage2 = styled('img')({
  width: 'full',
  height: 'auto',
  margin: '40px 20px 0',
  alignSelf: 'center',
});

const ConnectiveImage3 = styled('img')({
  width: 'full',
  height: 'auto',
  alignSelf: 'center',
});

const ConnectiveImage = styled('img')({
  width: '10px',
  height: 'auto',
  margin: '0 20px',
  alignSelf: 'center',
});

const RelativeContainer = styled(Box)({
  position: 'relative',
});

const AdjustableVerticalImage = styled('img')({
  position: 'absolute',
  top: '-10px',
  left: '45px',
  right: '0',
  bottom: '0',
  width: '2px',
  height: '50px',
});

const AdjustableVerticalImage2 = styled('img')({
  position: 'absolute',
  top: '-10px',
  left: '148px',
  right: '0',
  bottom: '0',
  width: '2px',
  height: '50px',
});

const AdjustableVerticalImage3 = styled('img')({
  position: 'absolute',
  top: '-10px',
  left: '-147px',
  right: '0',
  bottom: '0',
  width: '2px',
  height: '50px',
});

const AdjustableVerticalImage4 = styled('img')({
  position: 'absolute',
  top: '-10px',
  left: '-47px',
  right: '0',
  bottom: '0',
  width: '2px',
  height: '50px',
});

const StyledTypography = styled(Typography)(({ isVisible, delay }: { isVisible: boolean, delay: string }) => ({
  textAlign: 'center',
  marginBottom: '8px',
  fontFamily: 'Avenir-Medium',
  color: colors.blueButton,
  fontWeight: 'bold',
  minHeight: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 8px',
  fontSize: '0.875rem',
  opacity: 0,
  animation: isVisible ? `fadeIn 1s ${delay} forwards` : 'none',
  ...fadeIn,
}));

const ResponsiveHeading = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  color: colors.blueButton,
  fontFamily: 'Sansation',
  fontWeight: 'bold',
  fontSize: '48px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '24px',
  },
}));

const topRowColor = colors.backgroundBlue;

const PhoneViewComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once it's visible
          }
        });
      },
      { threshold: 0.3 } // Adjust the threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);


  return (
    <StyledPaper >
      <ResponsiveHeading variant="h3" gutterBottom align="center">
        How does it work?
      </ResponsiveHeading>

      <Box sx={{ my: 4 }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          bgcolor={topRowColor}
          width="96%"
          margin="auto"
          paddingTop="15px"
          paddingBottom="5px"
          borderRadius="15px"
          ref={containerRef}
        >
          <Grid item container direction="row" justifyContent="center" xs={12}>
            <ItemContainer>
              <Icon src="../../img/newhome/Row1Img1.svg" alt="Easy Team Collaboration Icon" isVisible={isVisible} delay="0.2s" />
              <StyledTypography isVisible={isVisible} delay="0.2s">Easy Team Collaboration</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/patientinteractionsettingsicon.svg" alt="Patient Interaction Setting Icon" isVisible={isVisible} delay="0.4s" />
              <StyledTypography isVisible={isVisible} delay="0.4s">Patient Interaction Setting</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row1Img3.svg" alt="API Integration Icon" isVisible={isVisible} delay="0.6s" />
              <StyledTypography isVisible={isVisible} delay="0.6s">API Integration</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row1Img4.svg" alt="Custom App Design Icon" isVisible={isVisible} delay="0.8s" />
              <StyledTypography isVisible={isVisible} delay="0.8s">Custom App Design</StyledTypography>
            </ItemContainer>
          </Grid>

          <RelativeContainer>
            <AdjustableVerticalImage src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage2 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage3 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage4 src="../../img/newhome/Line3.png" alt="Connective Image" />
          </RelativeContainer>

          <ConnectiveImage2 src="../../img/newhome/Line2.png" alt="Connective Image" />
          <ConnectiveImage src="../../img/newhome/LineImage.png" alt="Connective Image" />

          <ItemContainer>
            <Icon3 src="../../img/newhome/Desktop.svg" alt="Professional Dashboard" isVisible={isVisible} delay="1s" />
            <SectionHeading isVisible={isVisible} delay="1s">Professional Dashboard</SectionHeading>
          </ItemContainer>
        </Grid>
        <ConnectiveImage3 src="../../img/newhome/VerticalLine.svg" alt="Connective Image" />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          bgcolor={topRowColor}
          width="96%"
          margin="auto"
          paddingTop="15px"
          paddingBottom="5px"
          borderRadius="15px"
        >
          <Grid item container direction="row" justifyContent="center" xs={12}>
            <ItemContainer>
              <Icon src="../../img/newhome/Row2Img1.svg" alt="Symptoms" isVisible={isVisible} delay="1.2s" />
              <StyledTypography isVisible={isVisible} delay="1.2s">Symptoms</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row2Img2.svg" alt="Activity" isVisible={isVisible} delay="1.4s" />
              <StyledTypography isVisible={isVisible} delay="1.4s">Activity</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row2Img3.svg" alt="Heart Rate" isVisible={isVisible} delay="1.6s" />
              <StyledTypography isVisible={isVisible} delay="1.6s">Heart Rate</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row2Img4.svg" alt="Medication" isVisible={isVisible} delay="1.8s" />
              <StyledTypography isVisible={isVisible} delay="1.8s">Medication</StyledTypography>
            </ItemContainer>
          </Grid>

          <RelativeContainer>
            <AdjustableVerticalImage src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage2 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage3 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage4 src="../../img/newhome/Line3.png" alt="Connective Image" />
          </RelativeContainer>

          <ConnectiveImage2 src="../../img/newhome/Line2.png" alt="Connective Image" />
          <ConnectiveImage src="../../img/newhome/LineImage.png" alt="Connective Image" />

          <ItemContainer>
            <Icon2 src="../../img/newhome/Phone1.svg" alt="Patient Tool" isVisible={isVisible} delay="2s" />
            <SectionHeading isVisible={isVisible} delay="2s">Patient Tool</SectionHeading>
          </ItemContainer>
        </Grid>
        <ConnectiveImage3 src="../../img/newhome/VerticalLine.svg" alt="Connective Image" />
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          bgcolor={topRowColor}
          width="96%"
          margin="auto"
          paddingTop="15px"
          paddingBottom="5px"
          borderRadius="15px"
        >
          <Grid item container direction="row" justifyContent="center" xs={12}>
            <ItemContainer>
              <Icon src="../../img/newhome/Row3Img1.svg" alt="Complex Data Analysis" isVisible={isVisible} delay="2.2s" />
              <StyledTypography isVisible={isVisible} delay="2.2s">Complex Data Analysis</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row3Img2.svg" alt="Share Globally with institutions" isVisible={isVisible} delay="2.4s" />
              <StyledTypography isVisible={isVisible} delay="2.4s">Share Globally with institutions</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row3Img3.svg" alt="Hospital Quality Improvements" isVisible={isVisible} delay="2.6s" />
              <StyledTypography isVisible={isVisible} delay="2.6s">Hospital Quality Improvements</StyledTypography>
            </ItemContainer>
            <ItemContainer>
              <Icon src="../../img/newhome/Row3Img4.svg" alt="Clean Data for AI usage" isVisible={isVisible} delay="2.8s" />
              <StyledTypography isVisible={isVisible} delay="2.8s">Clean Data for AI usage</StyledTypography>
            </ItemContainer>
          </Grid>

          <RelativeContainer>
            <AdjustableVerticalImage src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage2 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage3 src="../../img/newhome/Line3.png" alt="Connective Image" />
            <AdjustableVerticalImage4 src="../../img/newhome/Line3.png" alt="Connective Image" />
          </RelativeContainer>

          <ConnectiveImage2 src="../../img/newhome/Line2.png" alt="Connective Image" />
          <ConnectiveImage src="../../img/newhome/LineImage.png" alt="Connective Image" />

          <ItemContainer>
            <Icon src="../../img/newhome/skull.png" alt="Professional Dashboard" isVisible={isVisible} delay="3s" />
            <SectionHeading isVisible={isVisible} delay="3s">Data Analysis</SectionHeading>
          </ItemContainer>
        </Grid>
      </Box>
    </StyledPaper>
  );
};

export default PhoneViewComponent;






