import React from "react";
// Images
import blogBulletPoint from '../../images/blog/blog-bulletpoint.svg';
//Components
import BlogList from './components/BlogList';
// CSS
import '../../css/blog.css';
import '../../css/general.css';
import '../../css/blog-responsive.css';

function Blog() {
    return (
        <div className="blog-container">
            <div className="blog-header">
                <img src={blogBulletPoint} alt="bullet point" />
                <h1>Sharing Meaningful Stories & Scientific Studies</h1>
            </div>
            <BlogList />
        </div>
    );
}

export default Blog;
