import React from "react";
//Images
import visualization from '../../../images/homepage/infographic.svg';
import smallInfographic from '../../../images/homepage/phone-infographic.svg';
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { NONAME } from "dns";

const ServicesButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "20rem",
        height: "3.0rem",
        fontSize: "1em",
        backgroundColor: "#ffffff",
        color: "#002145",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#002145",
            color: "#ffffff",
        },
        '@media only screen and (max-width: 580px)': {
            border: "solid 2px #002145",
            fontWeight: '600',
            width: "80%",
            height: "3.2rem",
        },
        '@media only screen and (max-width: 680px)': {
            display: "none",
        }
    },
}))(Button);

function Visualization() {
    return (
        <div id="learn_more_visual" className="visualization-container">
            <div className="visualization-content">
                <div className="visualization-title">
                    <h2>Streamline the administrative backend of your research</h2>
                </div>
                <img className="large-visualization" src={visualization} alt="infographic of Computing ReApplied's Value Add" />
                <img className="small-visualization" src={smallInfographic} alt="infographic of Computing ReApplied's Value Add" />
                <p id="contactform-redirect">Our user-centered design process ensures that our technology not only serves your exact needs, but is easy to use. All our services are also compatible with existing Microsoft 365 Enterprise subscriptions.</p>
                <a href="/pricing"><ServicesButton>Our Services</ServicesButton></a>
                <div>

                </div>
            </div>
        </div>
    );
}

export default Visualization;
