import React from "react";

function Video() {
    return (
        < div className="main-content" id="video-container">
            <h2>myMedDiary - What is it?</h2>
            <div className="Video">
                <iframe id="youtube-video"
                    width="560"
                    height="315"
                    src="https://www.youtube-nocookie.com/embed/4pntWggtPto"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </div>
            {/* end of main content */}
        </div >
    );
}
export default Video;