

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


const StyledAppBar = styled(AppBar)({
  backgroundColor: '#003366', // Adjust the color to match the image
});

const CircleIconButton = styled(IconButton)({
    color: '#003366',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    padding: '5px',
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  '& img': {
    width: '32px', 
    height: '30px' 
    
  }
});

const Navbar = () => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <CircleIconButton edge="start" color="inherit" aria-label="logo">
          <img src='images/Owl.png' alt="logo" />
        </CircleIconButton>
        <Typography variant="h6" color="inherit" component="div">
          Computing ReApplied
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <CircleIconButton edge="end" color="inherit" aria-label="menu">
          <MenuIcon />
        </CircleIconButton>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
