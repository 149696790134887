import React from 'react';
import Navbar from './Components/Navbar';
import Desktop1 from './Components/Desktop1';
import Desktop2 from './Components/Desktop2';
import Desktop3 from './Components/Desktop3';
import Desktop4 from './Components/Desktop4';
import Desktop5 from './Components/Desktop5';
import Desktop6 from './Components/Desktop6';
import Desktop7 from './Components/Desktop7';
import Desktop8 from './Components/Desktop8';
import Desktop9 from './Components/Desktop9';
import Desktop10 from './Components/Desktop10';
//import Footer from './Components/Footer';

function NewHome() {
    return (
        <>
            <Navbar />
            <Desktop1 />
            <Desktop2 />
            <Desktop3 />
            <Desktop4 />
            <Desktop5 />
            <Desktop6 />
            <Desktop7 />
            <Desktop8 />
            <Desktop9 />
            <Desktop10 />
            {/*<Footer />*/}
        </>
    );
}

export default NewHome;
