
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Paper, TextField, Button, useMediaQuery } from '@mui/material';
import { colors } from '../../../assets/Color/Color';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: colors.backgroundBlue,
  height: '100%',
  padding: '80px 0 80px 0',
  color: colors.blueButton,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  marginBottom: '102px',
  marginTop: '102px'
}));

const LeftSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const RightSection = styled(Grid)(({ theme }) => ({
  backgroundImage: 'url(../../img/newhome/BgLine.svg)',
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LaptopImage = styled('img')(({ theme }) => ({
  maxWidth: '648px',
  height: '347px',
  [theme.breakpoints.down('sm')]: {
    width: '345px',  // Smaller width on small screens
    height: '185px',
  },
}));



const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors.blueButton,
  color: colors.whitefont,
  '&:hover': {
    backgroundColor: colors.blueButton,
  },
  marginTop: theme.spacing(2),
}));


const handleButtonClick = () => {
  window.location.href = 'https://www.mymeddiary.app?auto=start';
};

const DemoComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledPaper elevation={0}>
      <Grid container>
        {isSmallScreen ? (
          <>
            <RightSection item xs={12}>
              <LaptopImage src="../../img/newhome/studydashrebuild1.svg" alt="Laptop" />
            </RightSection>
            <LeftSection item xs={12}>
              <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', fontFamily: 'Sansation', }}>
                All Kinds of Patient Data
              </Typography>
              <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', marginBottom: '30px', fontFamily: 'Sansation', }}>
                in One Place!
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Avenir-Heavy', }}>
                Ready to try myMedDiary for free
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px', fontFamily: 'Avenir-Heavy', }}>
                today?
              </Typography>

              <StyledButton onClick={handleButtonClick} variant="contained" fullWidth sx={{ maxWidth: '300px', height: '40px', backgroundColor: colors.blueButton, fontFamily: 'Avenir-Roman' }}>
                Start for Free →
              </StyledButton>
            </LeftSection>
          </>
        ) : (
          <>
            <LeftSection item xs={12} md={6}>
              <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '36px', textAlign: 'center', fontFamily: 'Sansation' }}>
                All Kinds of Patient Data
              </Typography>
              <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontSize: '36px', textAlign: 'center', marginBottom: '30px', fontFamily: 'Sansation' }}>
                in One Place!
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Avenir-Heavy' }}>
                Ready to try myMedDiary for free
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '40px', fontFamily: 'Avenir-Heavy' }}>
                today?
              </Typography>

              <StyledButton onClick={handleButtonClick} variant="contained" fullWidth sx={{ maxWidth: '300px', height: '40px', backgroundColor: colors.blueButton, fontFamily: 'Avenir-Roman', fontSize: { sm: "14px", md: '20px' }, textTransform: 'none' }}>
                Start for Free →
              </StyledButton>
            </LeftSection>
            <RightSection item xs={12} md={6}>
              <LaptopImage src="../../img/newhome/studydashrebuild1.svg" alt="Laptop" />
            </RightSection>
          </>
        )}
      </Grid>
    </StyledPaper>
  );
};

export default DemoComponent;