import React from "react";
//images
import seattleChildrens from '../../../images/icons/seattle-childrens.svg';
import microsoftStartups from '../../../images/icons/microsoft-for-startups.svg';
import BBB from '../../../images/icons/BBBLogo.png';
import StatlinkLogo from '../../../images/icons/statlinklogohorizontalline.svg';

import video from '../../../images/homepage/temp-vid.mp4';
import bannerMobile from '../../../images/homepage/temp-vision.jpg';
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const LearnButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "18rem",
        height: "2.7rem",
        fontSize: "1.1em",
        color: "#ffffff",
        backgroundColor: "#F07E03",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#ffffff",
            color: "#F07E03",
            border: "solid 1px #F07E03",
        },
        '@media only screen and (max-width: 680px)': {
            width: "100%",
        },
    },
}))(Button);

function Banner() {
    return (
        <div className="banner-background">
            {/* Banner Text */}
            <div className="banner-text">
                <h1>Optimizing Medical Research</h1>
                <p className="mobile-sub-header">Computing ReApplied&trade; (C-RA&trade;), your medical research partner.</p>
                <p className="sub-heading">
                    Where AI meets empathy. Computing ReApplied&trade; (C-RA&trade;) is a Seattle based company that works with medical entities to develop a suite of data processing tools that expedite their research.
          </p>
                <div className="bannerbutton-container">
                    <LearnButton variant="contained" color="primary" className="banner-button" href="#learn_more_visual">
                        Learn More
                </LearnButton>
                </div>
            </div>
            {/* Banner Icons */}
            <div className="banner-icons">
                <img className="banner-icon" src={seattleChildrens} alt="Seattle Children's Hospital Icon" />
               
                <img className="banner-icon" src={microsoftStartups} alt="Microsoft For Startups Icon" />
            </div>
            <div className="banner-icons-row-2">
                <img className="banner-icon" src={BBB} alt="BBB logo" />
                <img className="banner-icon-statlink" src={StatlinkLogo} alt="Statlink logo" />
            </div>

            {/* <div className="video-back">
          <div className="color-overlay"></div>
          <img src="bannerMobile" alt="Image of doctors working" />
          <video autoPlay loop muted preload="auto">
            <source src={video} />
          </video>
        </div> */}
        </div >
    );
}

export default Banner;
