import React from "react";
//Images
import vision from '../../../images/blog/CRAMicrosoft.png';
// Packages
import {
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ContactButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: "none",
        borderRadius: 23,
        fontFamily: "sans-serif",
        width: "20rem",
        height: "3.0rem",
        fontSize: "1em",
        backgroundColor: "#ffffff",
        color: "#002145",
        border: "solid 2px #002145",
        marginTop: "1rem",
        '&:hover': {
            backgroundColor: "#002145",
            color: "#ffffff",
        },
        '@media only screen and (max-width: 580px)': {
            border: "solid 2px #002145",
            fontWeight: '500', width: "80%",
            height: "3.2rem",
        }
    },
}))(Button);

function Article() {
    return (
        <div className="vision-container article-container">

            <div className="vision-contents">
                {/* vision text and button */}
                <div className="vision-text-container article-text-container">
                    <div className="vision-title">
                        <h5>Our Stories</h5>
                    </div>
                    <h2>C-RA's Sponsorship From Microsoft</h2>
                    <h4>Alex Barson</h4>
                    <p className="vision-p">C-RA&trade; is fortunate to be chosen for the Microsoft for Startups program. This program provides C-RA&trade; with access to technology otherwise out of reach to our team, and allows our applications to be listed in the Microsoft Marketplace, a perk we are very grateful to include in our arsenal. We are proud to be partnering with Microsoft, who we believe will be instrumental in our development as an influential company.</p>
                    <a href="/blog"><ContactButton>Our Blog</ContactButton></a>
                </div>
                {/* vision image */}
                <div className="vision-image article-image">
                    <img src={vision} alt="Medical researchers working" />
                </div>
            </div>

        </div>
    );
}

export default Article;

