import React from "react";
import { Link } from "react-router-dom";
// images
import closeMenu from "../../images/icons/white-close.svg";
import hamburger from "../../images/icons/white-hamburger.svg";
// CSS
import '../../css/header.css';
import '../../css/general.css';

// TODO https://www.youtube.com/watch?v=DZg6UfS5zYg <- check if the css for hamburger menu requires credit
function Hamburger() {

    return (
        <div className="menu-wrap">
            <input type="checkbox" className="toggler" />
            <div className="hamburger"><div></div></div>
            <div className="menu">
                <div>
                    <div>
                        <ul>
                            <li><a href="/">Home</a></li>
                            {/*<li><a href="/pricing">Pricing</a></li>*/}
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/pressrelease">Press Release</a></li>
                            <li><a target="_blank" href=" https://www.mymeddiary.app/onboarding">myMedDiary&reg;</a></li>
                            <li><a target="_blank" href="http://giving.uw.edu/OneHealthResearch" className="menunav-button" id='donate' >Donate To Medical Research</a></li>
                            {/*<li><a href="/team">The Team</a></li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hamburger;
