
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../../assets/Color/Color';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    width: '100%',
    padding: theme.spacing(1),
  },
  [theme.breakpoints.only('md')]: {
    width: '100%',
    margin: 0,
  },
  [theme.breakpoints.down('lg')]: {
    '& > .MuiGrid-item': {
      padding: theme.spacing(2),
    },
  },
}));

const ImageSection = styled(Box)(({ theme }) => ({
  maxWidth: '263px',
  position: 'relative',
  height: 'auto',
  margin: 'auto',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
    maxWidth: '146px',
  },
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
}));

const TextSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  borderRadius: theme.shape.borderRadius,
  marginLeft: '30px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const IconText = styled(Typography)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: theme.spacing(2),
  fontFamily: 'Avenir',
  color: colors.blueButton,
  fontWeight: 'bold',
  fontSize: '24px',
  marginBottom: '70px',
  textAlign: 'left',
  maxWidth: '100%',
  justifyContent: 'flex-start',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

const IconText2 = styled(Typography)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: theme.spacing(2),
  fontFamily: 'Avenir',
  color: colors.blueButton,
  fontWeight: 'bold',
  fontSize: '24px',
  marginBottom: '50px',
  textAlign: 'left',
  maxWidth: '100%',
  justifyContent: 'flex-start',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  [theme.breakpoints.only('md')]: {
    display: 'flex',
  },
}));

const IconText3 = styled(Typography)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: theme.spacing(2),
  fontFamily: 'Avenir',
  color: colors.blueButton,
  fontWeight: 'bold',
  fontSize: '16px',
  marginBottom: '50px',
  textAlign: 'left',
  maxWidth: '100%',
  justifyContent: 'flex-start',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
  },
}));

const ImageLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const ImageLabel2 = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'none',
  opacity: 0,
  transform: 'translateY(20px)',
  transition: 'opacity 0.5s ease, transform 0.5s ease',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const DataIntegrationComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated) {
            setIsVisible(true);
            setHasAnimated(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <StyledGrid container spacing={8} ref={containerRef}>
      <Grid item xs={12} md={6} style={{ backgroundColor: '#DBEEF2', borderRadius: '20px', height: '700px', marginTop: '20px' }}>
        <Box
          style={{
            padding: theme.spacing(1),
            fontSize: '24px',
            fontWeight: 'bold',
            margin: 'auto',
            textAlign: 'center',
            fontFamily: 'Sansation',
            color: colors.blueButton,
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.5s ease, transform 0.5s ease',
          }}
        >
          Integrating Medical Data <br /> with Digital Footprints:
        </Box>
        <ImageSection
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
          }}
        >
          <img src="../../img/newhome/bodypic.svg" alt="Integrating Medical Data" style={{ width: '90%', height: 'auto' }} />
          <ImageLabel
            style={{
              top: '10%',
              left: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2s' : '0s') : (isVisible ? '0.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/CtScan.svg" alt="CT Scan" style={{ width: '254px', height: '51px' }} />
          </ImageLabel>
          <ImageLabel2
            style={{
              top: '10%',
              left: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2s' : '0s') : (isVisible ? '0.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/CtScan.svg" alt="CT Scan" style={{ width: '140px', height: '28px' }} />
          </ImageLabel2>
          <ImageLabel
            style={{
              top: '25%',
              right: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2.5s' : '0s') : (isVisible ? '1s' : '0s')
            }}
          >
            <img src="../../img/newhome/Olevel.svg" alt="O2 Levels" style={{ width: '254px', height: '51px' }} />
          </ImageLabel>
          <ImageLabel2
            style={{
              top: '25%',
              right: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2.5s' : '0s') : (isVisible ? '1s' : '0s')
            }}
          >
            <img src="../../img/newhome/Olevel.svg" alt="O2 Levels" style={{ width: '140px', height: '28px' }} />
          </ImageLabel2>
          <ImageLabel
            style={{
              bottom: '50%',
              left: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '3s' : '0s') : (isVisible ? '1.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/GIOpsi.svg" alt="GI Biopsy" style={{ width: '254px', height: '51px' }} />
          </ImageLabel>
          <ImageLabel2
            style={{
              bottom: '50%',
              left: '-45%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '3s' : '0s') : (isVisible ? '1.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/GIOpsi.svg" alt="GI Biopsy" style={{ width: '140px', height: '28px' }} />
          </ImageLabel2>
          <ImageLabel
            style={{
              bottom: '30%',
              right: '65%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '3.5s' : '0s') : (isVisible ? '2s' : '0s')
            }}
          >
            <img src="../../img/newhome/Google.svg" alt="Google Searches" style={{ width: '192px', height: '51px' }} />
          </ImageLabel>
          <ImageLabel2
            style={{
              bottom: '30%',
              right: '65%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '3.5s' : '0s') : (isVisible ? '2s' : '0s')
            }}
          >
            <img src="../../img/newhome/Google.svg" alt="Google Searches" style={{ width: '106px', height: '28px' }} />
          </ImageLabel2>
          <ImageLabel
            style={{
              bottom: '15%',
              right: '-30%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '4s' : '0s') : (isVisible ? '2.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Social.svg" alt="Social Media" style={{ width: '200px', height: '51px' }} />
          </ImageLabel>
          <ImageLabel2
            style={{
              bottom: '15%',
              right: '-30%',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '4s' : '0s') : (isVisible ? '2.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Social.svg" alt="Social Media" style={{ width: '106px', height: '28px' }} />
          </ImageLabel2>
        </ImageSection>
      </Grid>



      <Grid item xs={12} md={6}>
        <TextSection>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{
              color: colors.blueButton,
              fontWeight: 'bold',
              fontSize: '48px',
              marginBottom: '100px',
              textAlign: 'center',
              display: isMobile ? 'none' : 'block',
              fontFamily: 'Sansation',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              transitionDelay: isMobile ? (isVisible ? '0.5s' : '0s') : (isVisible ? '3s' : '0s')
            }}
          >
            What is the purpose <br /> of all this data?
          </Typography>

          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{
              color: colors.blueButton,
              fontWeight: 'bold',
              fontSize: '24px',
              marginBottom: '70px',
              textAlign: 'center',
              display: isMobile ? 'block' : 'none',
              maxWidth: '100%',
              width: '100%',
              fontFamily: 'Sansation',
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              transitionDelay: isMobile ? (isVisible ? '0.5s' : '0s') : (isVisible ? '3s' : '0s')
            }}
          >
            What is the purpose of all this data?
          </Typography>

          <IconText
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1s' : '0s') : (isVisible ? '3.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Searchicon.svg" alt="Search" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            Your collected data can be <br /> used by third party AI models
          </IconText>
          <IconText
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1.5s' : '0s') : (isVisible ? '4s' : '0s')
            }}
          >
            <img src="../../img/newhome/Skull2.svg" alt="Computer" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            AI assisted software to <br /> parse through data
          </IconText>
          <IconText
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2s' : '0s') : (isVisible ? '4.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/persons.svg" alt="Analytics" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            More patient data means more <br /> in-depth analysis & knowledge
          </IconText>

          <IconText2
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1s' : '0s') : (isVisible ? '3.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Searchicon.svg" alt="Search" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            Your collected data can be used by third party AI models
          </IconText2>
          <IconText2
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1.5s' : '0s') : (isVisible ? '4s' : '0s')
            }}
          >
            <img src="../../img/newhome/Skull2.svg" alt="Computer" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            AI assisted software to parse through data
          </IconText2>
          <IconText2
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2s' : '0s') : (isVisible ? '4.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Persons.svg" alt="Analytics" style={{ width: '56px', height: '56px', marginRight: '20px' }} />
            More patient data means more in-depth analysis & knowledge
          </IconText2>

          <IconText3
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1s' : '0s') : (isVisible ? '3.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Searchicon.svg" alt="Search" style={{ width: '43px', height: '43px', marginRight: '20px' }} />
            Your collected data can be used by third party AI models
          </IconText3>
          <IconText3
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '1.5s' : '0s') : (isVisible ? '4s' : '0s')
            }}
          >
            <img src="../../img/newhome/Skull2.svg" alt="Computer" style={{ width: '43px', height: '43px', marginRight: '20px' }} />
            AI assisted software to parse through data
          </IconText3>
          <IconText3
            style={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              transitionDelay: isMobile ? (isVisible ? '2s' : '0s') : (isVisible ? '4.5s' : '0s')
            }}
          >
            <img src="../../img/newhome/Persons.png" alt="Analytics" style={{ width: '43px', height: '43px', marginRight: '20px' }} />
            More patient data means more in-depth analysis & knowledge
          </IconText3>
        </TextSection>
      </Grid>
    </StyledGrid>
  );
};

export default DataIntegrationComponent;
