import React, { useState, useEffect, useRef } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Typography, Grid, Paper } from '@mui/material';
import { colors } from '../../../assets/Color/Color';

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  overflow: 'hidden',
  margin: '0 auto',
  backgroundColor: colors.backgroundBlue,
  padding: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const LeftSectionBackground = styled(Grid)(({ theme }) => ({
  backgroundImage: 'url(../../img/newhome/LinesVector2.png)',
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '700px',
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: '270px',
  },
  '&.fade-in': {
    opacity: 1,
  },
}));

const FeatureBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

const FeatureItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(10),
  marginLeft: '95px',
  marginTop: '52px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    marginLeft: '15px',
  },
}));

const IconImage = styled('img')({
  width: '64px',
  height: '64px',
  marginRight: '24px',
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  '&.fade-in': {
    opacity: 1,
  },
});

const FeatureText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '16px',
  color: colors.blueButton,
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'Avenir',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  '&.fade-in': {
    opacity: 1,
  },
}));

const FeatureText2 = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontFamily: 'Avenir',
  color: colors.blueButton,
  display: 'none',
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
  '&.fade-in': {
    opacity: 1,
  },
}));

const ResponsiveHeading = styled(Typography)(({ theme }) => ({
  marginBottom: '64px',
  color: colors.blueButton,
  fontFamily: 'Sansation, sans-serif',
  fontWeight: 'bold',
  fontSize: '48px',
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '14px',
    marginTop: '100px',
  },
  '&.fade-in': {
    opacity: 1,
  },
}));

const ResponsiveVideo = styled('video')(({ theme }) => ({
  width: '100%',
  marginTop: '50px',
  opacity: 0,
  transition: 'opacity 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    height: '500px',
  },
  '&.fade-in': {
    opacity: 1,
  },
}));

const Desktop2 = () => {
  const [visibleItems, setVisibleItems] = useState([false, false, false, false, false, false, false]);
  const [headingVisible, setHeadingVisible] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);
  const sectionRef = useRef<HTMLVideoElement | null>(null);

  const baseDelay = 500; // Initial delay before starting the animations
  const intervalDelay = 500; // Delay between each icon and text animation

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setHeadingVisible(true);
          setImageVisible(true);
          const timers = visibleItems.map((_, index) =>
            setTimeout(() => {
              setVisibleItems((prev) =>
                prev.map((item, i) => (i === index ? true : item))
              );
            }, baseDelay + index * intervalDelay)
          );
          return () => timers.forEach(clearTimeout);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [visibleItems]);

  return (
    <StyledPaper elevation={0}>
      <ResponsiveHeading
        variant="h3"
        gutterBottom
        align="center"
        className={headingVisible ? 'fade-in' : ''}
      >
        Design Your Data
        <br />
        Collection App With Ease
      </ResponsiveHeading>
      <Grid container spacing={4} alignItems="stretch">
        <LeftSectionBackground
          item
          xs={12}
          md={6}
          className={imageVisible ? 'fade-in' : ''}
        >
          <ResponsiveVideo
            ref={sectionRef}
            src="../../img/newhome/GifVideo.mp4"
            autoPlay
            muted
            loop
            className={imageVisible ? 'fade-in' : ''}
          />
        </LeftSectionBackground>
        <FeatureBox item xs={12} md={6} container>
          {[
            {
              icon: '../../img/newhome/Icon1.svg',
              text: 'No-code HIPAA compliant app building service',
              text2: 'No-code HIPAA compliant<br/> app building service',
            },
            {
              icon: '../../img/newhome/Icon2.svg',
              text: 'Tailor UI/UX with ease for Diverse Studies and Clinical Applications',
              text2: 'Tailor UI/UX with ease for Diverse<br/> Studies and Clinical Applications',
            },
            {
              icon: '../../img/newhome/Icon3.svg',
              text: 'Syncs with Office 365, Apple Health, Google Fit, and any social media apps',
              text2: 'Syncs with Office 365, Apple Health,<br/> Google Fit, and any social media apps',
            },
          ].map((feature, index) => (
            <FeatureItem key={index}>
              <IconImage
                src={feature.icon}
                alt={`Icon ${index + 1}`}
                className={visibleItems[index * 2] ? 'fade-in' : ''}
              />
              <FeatureText className={visibleItems[index * 2 + 1] ? 'fade-in' : ''}>
                {feature.text}
              </FeatureText>
              <FeatureText2
                className={visibleItems[index * 2 + 1] ? 'fade-in' : ''}
                dangerouslySetInnerHTML={{ __html: feature.text2 }}
              />
            </FeatureItem>
          ))}
        </FeatureBox>
      </Grid>
    </StyledPaper>
  );
};

export default Desktop2;
