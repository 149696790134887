import React from "react";
// Components
import PressReleaseArticle from './PressReleaseArticle';
//Data
import pressreleasedata from './pressreleasedata.json';

const data = pressreleasedata.map(function (data, i) {
    return (
        <PressReleaseArticle articleData={data} key={i} />
    )
});

function PressReleaseList() {
    return (
        <div className="bloglist-container">
            {data}
        </div>
    );
}

export default PressReleaseList;
